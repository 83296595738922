import React, { useEffect, useState } from "react";
import styles from "./CustomButtons.module.css";
import { Button } from "@mui/material";
import { open_api } from "../../Utils/network";
function CustomButtons() {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    getTags();
  }, []);

  const getTags = () => {
    open_api
      .get(`/core/tags`)
      .then((res) => {
        // console.log(res?.data?.data);
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          setTags(res?.data?.data);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
    //  console.log(tags,"hii")
  };

  return (
    <div className={styles.container}>
      {btns.map((items) => {
        return (
          <Button className={styles.mulbutton} variant="outlined">
            {items.name}
          </Button>
        );
      })}
    </div>
  );
}

export default CustomButtons;
const btns = [
  { id: 1, name: "Emotional Development" },
  { id: 2, name: "Potty Training" },
  { id: 3, name: "Sleep Training" },
  { id: 4, name: "Nutrition and Wellness" },
];
