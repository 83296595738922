import React, { useEffect, useState } from 'react'
import styles from "./HomeDetails.module.css";
import parents from "../../Assets/prents.png";
import experts from "../../Assets/experts.png";
import rating from "../../Assets/rating.png";
import workshops from "../../Assets/workshops.png";
import HomeDetailsData from "../HomeDetailsDtata/HomeDetailsData";
import { open_api_without_auth } from '../../Utils/network';

function HomeDetails() {
const [details, setDetails] = useState();
  useEffect(() => {
    overalldetails()
  }, [])

  const overalldetails = () => {
    open_api_without_auth
    .get(`home/achievements`)
    .then((res) => {
      // console.log(res?.data);
      setDetails(res?.data)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const data = [
    {
      id: 1,
      img: parents,
      num: `${details?.users}+`,
      text: "Happy Parents",
    },
    {
      id: 2,
      img: workshops,
      num: `${details?.workshops}+`,
      text: "Workshops",
    },
    {
      id: 3,
      img: rating,
      num: details?.rating,
      text: "Rating",
    },
    {
      id: 4,
      img: experts,
      num: `${details?.experts}+`,
      text: "Experts",
    },
  ];
  return (
    <div className={styles.container}>
      {data.map((item) => {
         return (
        <HomeDetailsData item={item} key={item.id} />
         )
      })}
    </div>
  );
}
export default HomeDetails;

