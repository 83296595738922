import React, { useEffect, useState } from "react";
import styles from "./ExpertService.module.css";
import ExpertData from "../../Components/ExpertData/ExpertData";
import expertImg from "../../Assets/expertImg.png";
import { open_api } from "../../Utils/network";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";

function ExpertService() {
  const [expertlist, setExpertlist] = useState([]);

  useEffect(() => {
    getServicesExpertDetails();
  }, []);

  const getServicesExpertDetails = () => {
    open_api
      .get(`home/experts/`)
      .then((res) => {
        // console.log(res?.data?.data, "getExpertDetails");
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          setExpertlist(res?.data?.data);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
    // console.log(expertlist, "childcare")
  };

  function CustomPrevArrow(props) {
    const { onClick } = props;
    return (
      <button
        // className="custom-arrow-banner custom-prev-arrow-banner"
        className={`${styles.customarrow} ${styles.customprevarrow}`}
        onClick={onClick}
      >
        <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
      </button>
    );
  }

  function CustomNextArrow(props) {
    const { onClick } = props;
    return (
      <button
        // className="custom-arrow-banner custom-next-arrow-banner"
        className={`${styles.customarrow} ${styles.customnextarrow}`}
        onClick={onClick}
      >
        <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
      </button>
    );
  }

  var settings = {
    className: styles.slickstyles,
    // dots: true,
    autoplay: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: true,
    // fade: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <div className={styles.desktopContainer}>
        <div className={styles.weHave}>
          <h1 className={styles.h1}>Expert We Have</h1>
        </div>
        <div className={styles.expertList}>
          <Slider {...settings}>
            {expertlist?.map((item) => {
              return <ExpertData item={item} />;
            })}
          </Slider>
        </div>
      </div>

      <div className={styles.mobileContainer}>
        <div className={styles.weHave}>
          <h1 className={styles.h1}>Expert We Have</h1>
        </div>
        <div className={styles.expertList}>
          <Slider {...settings}>
            {expertlist?.map((item) => {
              return <ExpertData item={item} />;
            })}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default ExpertService;
const data = [
  {
    id: 1,
    img: expertImg,
    name: "Dr. Neha Shah ",
    desc: "Mental Health Expert",
  },
  {
    id: 2,
    img: expertImg,
    name: "Dr. Neha Shah ",
    desc: "Mental Health Expert",
  },
  {
    id: 3,
    img: expertImg,
    name: "Dr. Neha Shah ",
    desc: "Mental Health Expert",
  },
  {
    id: 4,
    img: expertImg,
    name: "Dr. Neha Shah ",
    desc: "Mental Health Expert",
  },
  {
    id: 5,
    img: expertImg,
    name: "Dr. Neha Shah ",
    desc: "Mental Health Expert",
  },
];
