import React from "react";
import styles from "./ServiceData.module.css";
import { Navigate, useNavigate } from "react-router-dom";

function ServiceData({ data }) {
  const navigate = useNavigate();
  const handleNavigate = (url) => {
    if (url) {
      navigate(url);
    }
  };
  return (
    <>
      <div
        className={styles.container}
        onClick={() => handleNavigate(data?.url)}
      >
        <div className={styles.img}>
          <img src={data.img} style={{ borderRadius: "10%" }}></img>
        </div>
        <div className={styles.desc}>
          <span>{data.desc}</span>
        </div>
      </div>
    </>
  );
}
export default ServiceData;
