import React, { useEffect, useState } from "react";
import styles from "./SliderBase.module.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SliderData from "./SliderData";
import Slider from "react-slick";
import { getBanners } from "../Dialog/helper";

function SliderBase({ page }) {
  const [homeBanner, setHomeBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const banners = await getBanners(page);
        setHomeBanner(banners || []);
      } catch (error) {
        console.error("Failed to fetch banners:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBanners();
  }, []);

  function CustomPrevArrow(props) {
    const { onClick } = props;
    return (
      <button
        className={`${styles.customarrow} ${styles.customprevarrow}`}
        onClick={onClick}
      >
        <ArrowBackIosNewIcon />
      </button>
    );
  }

  function CustomNextArrow(props) {
    const { onClick } = props;
    return (
      <button
        className={`${styles.customarrow} ${styles.customnextarrow}`}
        onClick={onClick}
      >
        <ArrowForwardIosIcon />
      </button>
    );
  }

  var settings = {
    className: styles.slickstyles,
    dots: homeBanner.length > 1,
    autoplay: homeBanner.length > 1,
    infinite: homeBanner.length > 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: false,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: homeBanner.length > 1,
        },
      },
    ],
  };

  if (loading) {
    return <p>Loading banners...</p>;
  }

  return (
    <div className={styles.container}>
      <Slider {...settings}>
        {homeBanner
          .filter((item) => item.is_active)
          .sort((a, b) => a.index - b.index)
          .map((item, index) => (
            <SliderData key={index} data={item} />
          ))}
      </Slider>
    </div>
  );
}

export default SliderBase;
