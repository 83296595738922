import React from "react";
import correct from "../../Assets/correct.png";
import styles from "./HomeTabs.module.css";
import sheth from "../../Assets/sheth.png";
import saxena from "../../Assets/saxena.png";
import deshmukh from "../../Assets/deshmukh.png";
import { Button } from "@mui/material";
import CareDetails from "../CareDetails/CareDetails";

function HomeTabs() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.top}>
          <h1>
            Care,
            <span> for every need </span>
          </h1>
        </div>
        <div className={styles.middle}>
          <span>Child Care</span>
          <span>Self Care</span>

          <span>Pregnancy Care </span>
        </div>

        <div className={styles.bottom}>
          <div className={styles.left}>
            <div className={styles.wrap}>
              <h1>Wellness sessions and resources for your team</h1>
              <div>
                <div className={styles.correct}>
                  <div>
                    <img src={correct}></img>
                  </div>
                  <p>Content across varied themes</p>
                </div>
                <div className={styles.correct}>
                  <div>
                    <img src={correct}></img>
                  </div>
                  <p>Interactive and engaging sessions</p>
                </div>
              </div>
            </div>
            <div className={styles.btn}>
              <Button variant="contained" className={styles.app}>Download the app</Button>
            </div>
          </div>
          <div className={styles.right}>
            {data.map((item) => {
              return <CareDetails data={item} key={item.id} />;
            })}
          </div>
        </div>

      </div>
    </>
  );
}
export default HomeTabs;
const data = [
  {
    id: 1,
    img: sheth,
    desc: "Wellness sessions with top creators",
    name: "Dr. Rikita Sheth,",
    special: "Health Expert & Educator",
  },
  {
    id: 2,
    img: saxena,
    desc: "Wellness sessions with top creators",
    name: "Dr. Rikita Sheth,",
    special: "Health Expert & Educator",
  },
  {
    id: 3,
    img: deshmukh,
    desc: "Wellness sessions with top creators",
    name: "Dr. Rikita Sheth,",
    special: "Health Expert & Educator",
  },
];
