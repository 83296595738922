import React, { useEffect, useState } from 'react'
import styles from './Offer.module.css'
import OfferDtata from '../OfferDtata/OfferDtata'
import OfferImg from '../../Assets/OfferImg.png'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { open_api_without_auth } from '../../Utils/network'

function Offer() {




    function CustomPrevArrow(props) {
        const { onClick } = props;
        return (
            <button
                // className="custom-arrow-banner custom-prev-arrow-banner"
                className={`${styles.customarrow} ${styles.customprevarrow}`}
                onClick={onClick}
            >
                <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
            </button>
        );
    }



    function CustomNextArrow(props) {
        const { onClick } = props;
        return (
            <button
                // className="custom-arrow-banner custom-next-arrow-banner"
                className={`${styles.customarrow} ${styles.customnextarrow}`}
                onClick={onClick}
            >
                <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
            </button>
        );
    }


    var settings = {
        className: styles.slickstyles,
        // dots: true,
        autoplay: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        adaptiveHeight: false,
        arrows: false,
        // fade: true,
        autoplaySpeed: 3000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1140,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.2,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };

    const [typeList, setTypeList] = useState([]);

    useEffect(() => {
        getExperType();
    }, [])
    

    const getExperType = () => {
        open_api_without_auth
        .get(`core/expert_type`)
        .then((res) => {
            setTypeList(res?.data?.results);
        })
        .catch((err) => console.log(err) )
    }
    return (<>
        <div className={styles.container}>
            <h1 className={styles.heading}>What We Offer</h1>
            <div className={styles.slideImages}>
                <Slider {...settings}>
                    {typeList?.map((item, i) => {

                        return (
<>
                            <OfferDtata data={item} key={i}/>
                            </>
                        )
                    })}
                </Slider>
            </div>
        </div>

        <div className={styles.mobileContainer}>
        <h1 className={styles.heading}>What We Offer</h1>            <div className={styles.slideImages}>
                <Slider {...settings}>
                    {typeList?.map((item,i) => {

                        return (

                            <OfferDtata data={item} key={i}/>
                        )
                    })}
                </Slider>
            </div>
        </div>
    </>)
}

export default Offer
const data = [
    {
        id: 1,

    }, {
        id: 2
    },
    {
        id: 3

    },
    {
        id: 4
    },
    {
        id: 6
    },
    {
        id: 7
    },
    {
        id: 8
    }
]