import { useState, useEffect } from "react";
import React from "react";
import "./UpcomingSession.css";
import { open_api_with_user_token } from "../../../Utils/network";
import UpcomingTimer from "./UpcomingTimer";
import emptyBox from "../../../Assets/Logo_icons/emptybox.svg";
import {
  NoPackagesContainer,
  NoPackagesImage,
  NoPackagesText,
} from "../../../Components/Dialog/PaymentDialogStyles";
import { fetchExpertDetails, fetchUserDetails } from "../../Dialog/helper";

const UpcomingSession = () => {
  const [upcomingSession, setUpcomingSession] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [enabledButtons, setEnabledButtons] = useState({});
  const [isExpertView, setIsExpertView] = useState(false);
  const [isExpert, setIsExpert] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("bearerToken");
    const storedUserId = localStorage.getItem("userId");
    const fetchOrdersData = async () => {
      try {
        setIsLoading(true);
        const userInfo = await fetchUserDetails(token, storedUserId);
        var expert_id = 0;
        const isExpert = userInfo.user_type_str === "Expert";
        setIsExpert(isExpert);
        if (isExpert) {
          expert_id = userInfo.expert_info.id;
        }
        const params =
          isExpert && isExpertView ? `&expert_id=${expert_id}` : "";

        const response = await open_api_with_user_token(token).get(
          `/session/booked_slot/purchased?page=1&size=5${params}`
        );
        if (Array.isArray(response.data.data)) {
          setUpcomingSession(response.data.data);
        } else {
          console.error("Fetched data is not an array:", response.data.data);
          setUpcomingSession([]);
        }

        setTotalPages(response.max_pages || 1);
      } catch (error) {
        console.error("Failed to fetch orders data", error);
        setUpcomingSession([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrdersData();
  }, [currentPage, isExpertView]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    const daySuffix = (d) => {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${daySuffix(day)} ${month} ${year}`;
  }

  function formatTime(timeString) {
    let [hours, minutes] = timeString.split(":");
    hours = parseInt(hours);
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  }

  const handleEnableButton = (sessionId, isEnabled) => {
    setEnabledButtons((prev) => ({
      ...prev,
      [sessionId]: isEnabled,
    }));
  };

  if (upcomingSession.length === 0) {
    return (
      <>
        <div className="sessions-header">
          <div className="heading">
            <h2>Upcoming Session</h2>
            {isExpert && (
              <div className="toggle-container">
                <span>Expert View:</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isExpertView}
                    onChange={(e) => setIsExpertView(e.target.checked)}
                  />
                  <span className="slider"></span>
                </label>
              </div>
            )}
          </div>

          <NoPackagesContainer>
            <NoPackagesImage src={emptyBox} alt="No upcoming session" />
            <NoPackagesText>No upcoming session</NoPackagesText>
          </NoPackagesContainer>
        </div>
        {isExpert && (
          <span>
            *you can toggle expert view to see your upcoming sessions with
            clients(parents)
          </span>
        )}
      </>
    );
  }

  return (
    <div className="sessions-page">
      <div className="sessions-header">
        <div className="heading">
          <h2>Upcoming Session</h2>
          {isExpert && (
            <div className="toggle-container">
              <span>Expert View:</span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={isExpertView}
                  onChange={(e) => setIsExpertView(e.target.checked)}
                />
                <span className="slider"></span>
              </label>
            </div>
          )}
        </div>
      </div>

      <div className="sessions-list">
        {upcomingSession.map((session) => (
          <div key={session?.id} className="session-card">
            <div className="session-info">
              <div className="session-id">Session ID: {session?.slot?.id}</div>
              <div className="session-expert">
                {isExpert ? (
                  <>
                    User :{" "}
                    <span style={{ fontWeight: 500 }}>
                      {session?.user?.first_name +
                        " " +
                        session?.user?.last_name}
                    </span>
                  </>
                ) : (
                  <>
                    Expert :{" "}
                    <span style={{ fontWeight: 500 }}>
                      {session?.slot?.expert?.first_name +
                        " " +
                        session?.slot?.expert?.last_name}
                    </span>
                  </>
                )}
              </div>
              <div className="session-date">
                Date :{" "}
                <span style={{ fontWeight: 500 }}>
                  {formatDate(session?.slot?.date)}
                </span>
              </div>
              <div className="session-time">
                Time :{" "}
                <span style={{ fontWeight: 500 }}>
                  {formatTime(session?.slot?.time)}
                </span>
              </div>
            </div>
            <div className="session-meta">
              <div className="session-timer-label">
                Session{" "}
                <span className="session-timer-highlight">Starts in</span>
              </div>
              <div className="session-timer-placeholder">
                <UpcomingTimer
                  targetDate={session?.slot?.date}
                  targetTime={session?.slot?.time}
                  onEnableButton={(isEnabled) =>
                    handleEnableButton(session.id, isEnabled)
                  }
                  sessionId={session.id}
                />
              </div>
              <button
                className={`join-now-button ${
                  enabledButtons[session.id] ? "enabled" : "disabled"
                }`}
                disabled={!enabledButtons[session.id]}
                onClick={() => {
                  if (enabledButtons[session.id]) {
                    window.open(session?.jitsi_meeting_link, "_blank");
                  }
                }}
                style={{
                  backgroundColor: enabledButtons[session.id]
                    ? "#ff4d79"
                    : "grey",
                }}
              >
                {isExpert && isExpertView ? "Join as an expert" : "Join now"}
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            className={`page-button ${currentPage === index + 1 ? "active" : ""}`}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <div className="upcoming-session-additional-info">
        <span style={{ marginTop: 5 }}>
          *Join now button will be enabled 30mins before the session
        </span>
        {isExpert && (
          <span>
            *you can toggle expert view to see your upcoming sessions with
            clients(parents)
          </span>
        )}
      </div>
    </div>
  );
};

export default UpcomingSession;
