import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  SuccessMessage,
  Label,
  InputField,
  ErrorText,
  Select,
  OtpContainer,
} from "../Dialog/PaymentDialogStyles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField"; // Import TextField from Material UI
import dayjs from "dayjs";
import { open_api_without_auth } from "../../Utils/network";
import Autocomplete from "@mui/material/Autocomplete";

const UserProfileForm = ({
  useEmail,
  email,
  isdCode,
  countrycodeISO,
  CustomPhoneInput,
  phoneNumber,
  usePhone,
  firstName,
  lastName,
  dateOfBirth,
  city,
  profileType,
  profileTypes,
  firstNameError,
  lastNameError,
  phoneNumberError,
  emailError,
  dateOfBirthError,
  cityError,
  profileTypeError,
  handlefirstNameChange,
  handlelastNameChange,
  handlePhoneNumberInputChange,
  handleEmailInputChange,
  handleDateOfBirthChange,
  handleCityChange,
  handleProfileTypeChange,
}) => {
  useEmail = localStorage.getItem("useEmail") === "true";
  usePhone = localStorage.getItem("usePhone") === "true";
  const [cities, setCities] = useState([]);
  const countryCode = sessionStorage.getItem("country_code");
  useEffect(() => {
    getCities();
  }, []);

  const getCities = async () => {
    try {
      const res = await open_api_without_auth.get("/core/city");
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        setCities(res.data.citys.flat());
      }
    } catch (error) {}
  };

  return (
    <div>
      <SuccessMessage>
        <CheckCircleIcon color="success" />
        <h3>
          Your {useEmail ? "email" : "phone number"}{" "}
          {useEmail ? email : `+${isdCode} ${phoneNumber}`} has been verified
          successfully
        </h3>
      </SuccessMessage>

      {/* First Name */}
      <Label>First Name:</Label>
      <InputField
        name="firstName"
        type="text"
        placeholder="Enter your first name"
        value={firstName}
        onChange={handlefirstNameChange}
        style={{
          borderColor: firstNameError ? "red" : "#ccc",
        }}
      />
      {firstNameError && (
        <ErrorText>
          This field is required and must contain only letters
        </ErrorText>
      )}

      {/* Last Name */}
      <Label>Last Name:</Label>
      <InputField
        name="lastName"
        type="text"
        placeholder="Enter your last name"
        value={lastName}
        onChange={handlelastNameChange}
        style={{
          borderColor: lastNameError ? "red" : "#ccc",
        }}
      />
      {lastNameError && (
        <ErrorText>
          This field is required and must contain only letters
        </ErrorText>
      )}

      {/* Phone Number */}
      <Label>Phone Number:</Label>
      <OtpContainer
        style={{
          marginTop: "0",
          borderColor: phoneNumberError ? "red" : "#ccc",
        }}
      >
        <CustomPhoneInput
          defaultCountry={countrycodeISO.toLowerCase() ?? "in"}
          disableDialCodeAndPrefix={true}
          placeholder="Enter your phone number"
          value={phoneNumber}
          onChange={handlePhoneNumberInputChange}
          style={{
            borderColor: phoneNumberError ? "red" : "#ccc",
          }}
          disabled={usePhone}
        />
      </OtpContainer>
      {phoneNumberError && (
        <ErrorText>
          This field is required and enter a valid phone number
        </ErrorText>
      )}

      {/* Email */}
      <Label>Email:</Label>
      <InputField
        name="email"
        type="email"
        placeholder="Enter your email id"
        value={email}
        onChange={handleEmailInputChange}
        style={{
          borderColor: emailError ? "red" : "#ccc",
        }}
        disabled={useEmail}
      />
      {emailError && (
        <ErrorText>This field is required and enter a valid email</ErrorText>
      )}

      {/* Date of Birth */}
      <Label>Date of Birth:</Label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Date of Birth"
          value={dayjs(dateOfBirth)}
          onChange={handleDateOfBirthChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      {dateOfBirthError && (
        <ErrorText>Please select a valid date of birth</ErrorText>
      )}

      {/* City */}
      {countryCode === "in" ? (
        <>
          <Label>City:</Label>
          <Autocomplete
            options={cities}
            getOptionLabel={(cityName) => cityName}
            value={city}
            onChange={(event, newValue) =>
              handleCityChange({ target: { name: "city", value: newValue } })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Enter your city"
                style={{
                  borderColor: cityError ? "red" : "#ccc",
                }}
              />
            )}
          />
          {cityError && <ErrorText>This field is required</ErrorText>}
        </>
      ) : (
        <></>
      )}

      {/* Profile Type */}
      <Label htmlFor="profileType">Select Profile Type:</Label>
      <Select
        id="profileType"
        name="profileType"
        value={
          profileTypes.find((type) => type.title === profileType)?.id || ""
        }
        onChange={handleProfileTypeChange}
        style={{
          borderColor: profileTypeError ? "red" : "#ccc",
        }}
      >
        <option value="">Select a profile type</option>
        {profileTypes.map((type) => (
          <option key={type.id} value={type.id}>
            {type.title}
          </option>
        ))}
      </Select>
      {profileTypeError && <ErrorText>This field is required</ErrorText>}
    </div>
  );
};

export default UserProfileForm;
