import React from 'react'
import styles from "./index.module.css"
import ContactUsFrom from '../../Components/ContactUsFrom/ContactUsFrom'

function ContactUs() {
  return (
    <>
      <ContactUsFrom />
    </>
  )
}

export default ContactUs
