import React from "react";
import styles from "./index.module.css";
import quoteimg from "../../Assets/expert_page/bgQuote.png";
import clinetimg from "../../Assets/expert_page/userP.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function CustomPrevArrow(props) {
  const { onClick } = props;
  return (
    <button
      // className="custom-arrow-banner custom-prev-arrow-banner"
      className={`${styles.customarrow} ${styles.customprevarrow}`}
      onClick={onClick}
    >
      <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
    </button>
  );
}

function CustomNextArrow(props) {
  const { onClick } = props;
  return (
    <button
      // className="custom-arrow-banner custom-next-arrow-banner"
      className={`${styles.customarrow} ${styles.customnextarrow}`}
      onClick={onClick}
    >
      <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
    </button>
  );
}

var settings = {
  className: styles.slickstyles,
  // dots: true,
  autoplay: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: false,
  arrows: true,
  // fade: true,
  autoplaySpeed: 2000,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  responsive: [
    {
      breakpoint: 1140,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

function ClientreviewCard({ data }) {
  // console.log(data, "ClientreviewCard");
  return (
    <>
      <Slider {...settings}>
        {data?.map((v, i) => {
          return (
            <>
              <div className={styles.cardContainer}>
                <div className={styles.imgContainer}>
                  <img src={v?.profile_image} alt="" />
                </div>
                <h5>{v?.name}</h5>
                <p>Parent</p>
                <div className={styles.reviewsContainer}>
                  <p>{v?.text}</p>
                  <div className={styles.bgquote}>
                    <img src={quoteimg} alt="" />
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </Slider>
    </>
  );
}

export default ClientreviewCard;
