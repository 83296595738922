import React from "react";
import styles from "./index.module.css";
import { Button, Rating } from "@mui/material";
import cardimg from "../../Assets/expert_page/wp.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import dummy from "../../Assets/dummy.png";
import { useNavigate, useParams } from "react-router-dom";

function CustomPrevArrow(props) {
  const { onClick } = props;
  return (
    <button
      // className="custom-arrow-banner custom-prev-arrow-banner"
      className={`${styles.customarrow} ${styles.customprevarrow}`}
      onClick={onClick}
    >
      <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
    </button>
  );
}

function CustomNextArrow(props) {
  const { onClick } = props;
  return (
    <button
      // className="custom-arrow-banner custom-next-arrow-banner"
      className={`${styles.customarrow} ${styles.customnextarrow}`}
      onClick={onClick}
    >
      <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
    </button>
  );
}

var settings = {
  className: styles.slickstyles,
  dots: true,
  autoplay: true,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: false,
  arrows: false,
  // fade: true,
  autoplaySpeed: 2000,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

function WorkshopCard({ data }) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const handleBuyNow = (workshopSlug) => {
    navigate(`/workshop/${workshopSlug}`);
  };
  // console.log(data, "WorkshopCard");
  return (
    <>
      <Slider {...settings}>
        {data?.map((v, i) => {
          return (
            <>
              <div className={styles.cardContainer}>
                <div className={styles.imgContainer}>
                  <img src={v?.file ? v.file : dummy} alt="" />
                </div>
                <div className={styles.detailsContainer}>
                  <h3 className={styles.cardName}>{v?.title}</h3>
                  <h5 className={styles.expertName}>
                    {v?.workshop_experts[0]?.first_name}{" "}
                    {v?.workshop_experts[0]?.last_name}
                  </h5>
                  <div className={styles.ratingcontainer}>
                    <span>4.0</span>{" "}
                    <Rating
                      name="read-only"
                      value={4.5}
                      readOnly
                      precision={0.5}
                      max={1}
                    />
                  </div>
                  <div className={styles.priceContainer}>
                    <div className={styles.PriceLeft}>
                      <span style={{ textDecoration: "line-through" }}>
                        ₹ {v?.mrp}
                      </span>{" "}
                      ₹ {v?.selling_price}
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        className={styles.allActionBtn}
                        onClick={() => handleBuyNow(v.slug)}
                      >
                        Buy Now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
        {/* <div className={styles.cardContainer}>
          <div className={styles.imgContainer}>
            <img src={cardimg} alt="" />
          </div>
          <div className={styles.detailsContainer}>
            <h3 className={styles.cardName}>
              Why do babies throw tantrum so often?
            </h3>
            <h5 className={styles.expertName}>Dr. Richa Shah</h5>
            <div className={styles.ratingcontainer}>
              <span>4.0</span>{" "}
              <Rating name="read-only" value={4.5} readOnly precision={0.5} />
            </div>
            <div className={styles.priceContainer}>
              <div className={styles.PriceLeft}>
                <span style={{ textDecoration: "line-through" }}>₹1499</span>{" "}
                ₹1299
              </div>
              <div>
                <Button variant="contained" className={styles.allActionBtn}>
                  Buy Now
                </Button>
              </div>
            </div>
          </div>
        </div> */}
      </Slider>
    </>
  );
}

export default WorkshopCard;

const carddetails = [
  {
    id: 1,
    img: cardimg,
    title: "Why do babies throw tantrum so often?",
    expert: "Dr. Richa Shah",
    rating: 4.5,
    maxvalue: "₹1499",
    minvalue: "₹1299",
  },
  {
    id: 2,
    img: cardimg,
    title: "Why do babies throw tantrum so often?",
    expert: "Dr. Richa Shah",
    rating: 4.5,
    maxvalue: "₹1499",
    minvalue: "₹1299",
  },
  {
    id: 3,
    img: cardimg,
    title: "Why do babies throw tantrum so often?",
    expert: "Dr. Richa Shah",
    rating: 4.5,
    maxvalue: "₹1499",
    minvalue: "₹1299",
  },
  {
    id: 4,
    img: cardimg,
    title: "Why do babies throw tantrum so often?",
    expert: "Dr. Richa Shah",
    rating: 4.5,
    maxvalue: "₹1499",
    minvalue: "₹1299",
  },
  {
    id: 5,
    img: cardimg,
    title: "Why do babies throw tantrum so often?",
    expert: "Dr. Richa Shah",
    rating: 4.5,
    maxvalue: "₹1499",
    minvalue: "₹1299",
  },
];
