import React, { useEffect, useState } from 'react'
import ClientreviewCard from '../ClientreviewCard/ClientreviewCard'
import { open_api } from '../../Utils/network'

function HomeReviwCard() {
    const [review, setReview] = useState([])

useEffect(() => {
    homeReviewCard()
},[])
    const homeReviewCard = () => {
        open_api
        .get(`home/testimonials`)
        .then((res) => {
            // console.log(res?.data?.results);
            setReview(res?.data?.results)
        })
        .catch((err) => {
            console.log(err);
        })
    }
  return (
    <>
      <ClientreviewCard  data={review}/>
    </>
  )
}

export default HomeReviwCard
