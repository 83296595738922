import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import EbookDialogContent from "../../Components/PopUp/EbookDialogContent";
import backgroundImage from "../../Assets/popup/backgroundImage2.png";
import myburgoLogo from "../../Assets/Logo_icons/ebookMyburgoLogo.svg"; // Ensure correct path
import topicsToTeachCover from "../../Assets/popup/topic-for-kids-cover.png";
import { useParams } from "react-router-dom";
import PageNotFound from "../PageNotFound/PageNotFound";
import { open_api_without_auth } from "../../Utils/network";
import LoadingOverlay from "../../Components/Dialog/LoadingOverlay";

function EbookDownloadPage() {
  const { expertslug, itemSlug } = useParams();
  const [hasContent, setHasContent] = useState(false);
  const [ebookData, setEbookData] = useState();
  const [loading, setLoading] = useState(false);
  // Corrected API URL function

  useEffect(() => {
    const fetchPopupContent = async () => {
      try {
        setLoading(true);
        const response = await open_api_without_auth.get(
          `lead/lead-capture-page/get-lead-capture-page/?expertslug=${expertslug}&type=${itemSlug}`
        );
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 204
        ) {
          if (response.data) {
            setEbookData(response.data.results[0]);
            setHasContent(true);
          } else {
            setHasContent(false);
          }
        } else {
          setHasContent(false);
        }
      } catch (e) {
        setHasContent(false);
      } finally {
        setLoading(false);
      }
    };

    fetchPopupContent();
  }, [expertslug, itemSlug]);

  if (!hasContent && !loading) {
    return <PageNotFound />;
  }
  return (
    <>
      {loading ? (
        <LoadingOverlay />
      ) : (
        <div className={styles.mainContainer}>
          {/* Logo Section */}
          <div className={styles.logoContainer}>
            <img src={myburgoLogo} alt="Myburgo Logo" className={styles.logo} />
          </div>

          <div className={styles.cardContainer}>
            {/* Left side - Image */}
            <div
              className={styles.cardImage}
              style={{ backgroundImage: `url(${ebookData?.image})` }}
            />

            {/* Right side - EbookDialogContent */}
            <div className={styles.cardContent}>
              <EbookDialogContent ebookData={ebookData} hidebg={true} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EbookDownloadPage;
