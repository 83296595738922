import React, { useEffect, useState } from "react";
import HomeSLiderData from "../../Components/Slider/SliderData";
import styles from "./PragnancyCare.module.css";
import CustomButtons from "../../Components/CustomButtons/CustomButtons";
import SpecialitiesCard from "../../Components/SpecialitiesCard/SpecialitiesCard";
import cards from "../../Assets/cards.png";
import Offer from "../../Components/Offer/Offer";
import Recommended from "../../Components/Recommended/Recommended";
import ExpertWeHave from "../../Components/ExpertWeHave/ExpertWeHave";
import ExpertService from "../../Components/ExpertService/ExpertService";
import ClientreviewCard from "../../Components/ClientreviewCard/ClientreviewCard";
import BlogsCard from "../../Components/BlogsCard/BlogsCard";
import AboutUs from "../../Components/AboutUs/AboutUs";
import ExpertDetailsWorkshop from "../../Components/ExpertDetailsWorkshop/ExpertDetailsWorkshop";
import BlogSlider from "../../Components/BlogSlider/BlogSlider";
import HomeSlider from "../../Components/Slider/SliderBase";
import PricingCards from "../../Components/PricingCards/PricingCards";
import { open_api } from "../../Utils/network";
function PragnancyCare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [preReview, setPreReview] = useState([]);

  useEffect(() => {
    homeReviewCard();
  }, []);
  const homeReviewCard = () => {
    open_api
      .get(`home/testimonials`)
      .then((res) => {
        // console.log(res?.data?.results);
        setPreReview(res?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className={styles.container}>
      <section>
        <div>
          <HomeSlider />
        </div>
      </section>

      <section className={styles.heads}>
        <h1 className={styles.pregnancy}>Pregnancy Care Workshops</h1>
        <CustomButtons />
      </section>

      <section className={styles.cards}>
        <PricingCards />
      </section>

      <section>
        <Offer />
      </section>

      <section>
        <Recommended />
      </section>
      <section></section>

      <section>
        <ExpertService />
      </section>
      <section className={styles.ourclient}>
        <div className={styles.clients}>
          <p className={styles.clientp}>OUR CLIENTS</p>
          <h1 className={styles.clienth1}>Testimonials</h1>
        </div>
        <ClientreviewCard data={preReview} />
      </section>
      <div className={styles.bgColor}>
        <section className={styles.blogs}>
          <div className={styles.head}>
            <h1>BLOGS</h1>
          </div>
          <BlogSlider />
        </section>

        <section>
          <AboutUs />
        </section>
      </div>
    </div>
  );
}

export default PragnancyCare;

const data = [
  {
    id: 1,
    title: "Why do children cry in young age?",
    img: cards,
  },
  {
    id: 2,
    title: "Why do children cry in young age?",
    img: cards,
  },
  {
    id: 3,
    title: "Why do children cry in young age?",
    img: cards,
  },
  {
    id: 4,
    title: "Why do children cry in young age?",
    img: cards,
  },
  {
    id: 5,
    title: "Why do children cry in young age?",
    img: cards,
  },
  {
    id: 6,
    title: "Why do children cry in young age?",
    img: cards,
  },
  {
    id: 7,
    title: "Why do children cry in young age?",
    img: cards,
  },
];
