import React from "react";
import "./LoadingOverlay.css";

const LoadingOverlay = ({ message }) => (
  <div className="loading-overlay">
    <div className="spinner"></div>
    {message && <p className="loading-message">{message}</p>}
  </div>
);

export default LoadingOverlay;
