import React, { useEffect, useState } from "react";
import styles from "./ExpertWeHave.module.css";
import bgHearts from "../../Assets/bgHearts.png";
import btmsum from "../../Assets/btmsum.png";
import btmstar from "../../Assets/btmstar.png";
import expertImg from "../../Assets/expertImg.png";
import ExpertData from "../../Components/ExpertData/ExpertData";
import { open_api } from "../../Utils/network";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

function ExpertWeHave() {
  const [expert, setExpert] = useState([]);

  useEffect(() => {
    getHomeExpertDetails();
  }, []);

  const getHomeExpertDetails = () => {
    open_api
      .get(`home/experts/`)
      .then((res) => {
        // console.log(res?.data?.data, "getExpertDetails");
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          setExpert(res?.data?.data);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  function CustomPrevArroW(props) {
    const { onClick } = props;
    return (
      <button
        // className="custom-arrow-banner custom-prev-arrow-banner"
        className={`${styles.customarrow} ${styles.customprevarrow}`}
        onClick={onClick}
      >
        <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
      </button>
    );
  }

  function CustomNextArrow(props) {
    const { onClick } = props;
    return (
      <button
        // className="custom-arrow-banner custom-next-arrow-banner"
        className={`${styles.customarrow} ${styles.customnextarrow}`}
        onClick={onClick}
      >
        <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
      </button>
    );
  }

  var settings = {
    className: styles.slickstyles,
    dots: false,
    autoplay: expert.length > 4,
    infinite: expert.length > 4,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: true,
    // fade: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevArroW />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
          autoplay: true,
          infinite: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  return (
    <div className={styles.container}>
      <div className={styles.experTop}>
        <div className={styles.img}>
          <img src={bgHearts}></img>
        </div>
      </div>

      <div className={styles.expetBottom}>
        <div className={styles.btmsum}>
          <img src={btmsum}></img>
        </div>
        <div className={styles.btmstar}>
          <img src={btmstar}></img>
        </div>

        <div className={styles.weHave}>
          <h1 className={styles.experth1}>Expert We Have</h1>
        </div>
        <div className={styles.expertList}>
          <Slider {...settings}>
            {expert?.map((item) => {
              return <ExpertData item={item} />;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default ExpertWeHave;
const data = [
  {
    id: 1,
    img: expertImg,
    name: "Dr. Neha Shah ",
    desc: "Mental Health Expert",
  },
  {
    id: 2,
    img: expertImg,
    name: "Dr. Neha Shah ",
    desc: "Mental Health Expert",
  },
  {
    id: 3,
    img: expertImg,
    name: "Dr. Neha Shah ",
    desc: "Mental Health Expert",
  },
  {
    id: 4,
    img: expertImg,
    name: "Dr. Neha Shah ",
    desc: "Mental Health Expert",
  },
  {
    id: 5,
    img: expertImg,
    name: "Dr. Neha Shah ",
    desc: "Mental Health Expert",
  },
];
