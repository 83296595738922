import React, { useEffect } from "react";
import styled, { keyframes, css } from "styled-components";

// Slide-in animation
const slideIn = keyframes`
  from {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
`;

// Styles based on alert type
const alertStyles = {
  success: {
    backgroundColor: "#d4edda",
    color: "#155724",
    borderColor: "#c3e6cb",
  },
  error: {
    backgroundColor: "#f8d7da",
    color: "#721c24",
    borderColor: "#f5c6cb",
  },
  warning: {
    backgroundColor: "#fff3cd",
    color: "#856404",
    borderColor: "#ffeeba",
  },
};

// Styled-components
const AlertWrapper = styled.div`
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  border: 1px solid;
  border-radius: 4px;
  padding: 16px 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  animation: ${slideIn} 0.3s ease-out;
  ${({ type }) =>
    type &&
    css`
      background-color: ${alertStyles[type].backgroundColor};
      color: ${alertStyles[type].color};
      border-color: ${alertStyles[type].borderColor};
    `}

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    width: 70%;
  }
`;

const AlertTitle = styled.h4`
  margin: 0;
  font-size: 1.2em;
`;

const AlertMessage = styled.p`
  margin: 8px 0 0;
  font-size: 1em;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  font-size: 1.2em;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

const CustomAlert = ({ title, message, onClose, autoClose, type }) => {
  useEffect(() => {
    if (autoClose) {
      const timer = setTimeout(onClose, 3000);
      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, [onClose, autoClose]);

  return (
    <AlertWrapper type={type}>
      <CloseButton onClick={onClose}>&times;</CloseButton>
      <AlertTitle>{title}</AlertTitle>
      <AlertMessage>{message}</AlertMessage>
    </AlertWrapper>
  );
};

export default CustomAlert;
