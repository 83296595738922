import React, { useEffect, useState } from 'react'
import WorkshopCard from '../WorkshopCard/WorkshopCard';
import { open_api } from '../../Utils/network';

function PricingCards() {
    const [details, setDetails] = useState([])

    useEffect(() => {
        getWorkshop();
    }, [])
    const getWorkshop = (id) => {

        open_api
            .get(`home/workshop/`)
            // .get(`profiles/experts/${id}/workshops`)
            .then((res) => {
                // console.log(res?.data?.data);
                if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
                    setDetails(res?.data?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
            // console.log(details,"pricing")
    };
    return (
        <div>
            <WorkshopCard data={details} />

        </div>
    )
}

export default PricingCards