import React, { useEffect, useState } from "react";
import WorkshopCard from "../WorkshopCard/WorkshopCard";
import { open_api } from "../../Utils/network";

function ExpertDetailsWorkshop({ slug }) {
  const [workShopList, setWorkshopList] = useState([]);

  useEffect(() => {
    if (slug) {
      getWorkshop(slug);
    }
  }, [slug]);

  // console.log(slug, "ExpertDetailsWorkshop");

  const getWorkshop = (id) => {
    open_api
      // .get(`workshop/expert_workshops/${id}`)
      .get(`profiles/experts/${id}/workshops`)
      .then((res) => {
        // console.log(res?.data?.data);
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          setWorkshopList(res?.data?.results);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  return (
    <>
      <WorkshopCard data={workShopList} />
    </>
  );
}

export default ExpertDetailsWorkshop;
