import React, { useEffect, useState } from "react";
import styles from "./SliderData.module.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function HomeSLiderData({ data }) {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (data.is_timer && data.timer_duration) {
      const [hours, minutes, seconds] = data.timer_duration
        .split(":")
        .map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds; // Convert the timer to total seconds
      setTimer(totalSeconds);
    }
  }, [data.is_timer, data.timer_duration]);

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }

    return () => clearInterval(countdown); // Cleanup interval on component unmount
  }, [timer]);

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const s = (seconds % 60).toString().padStart(2, "0");
    return `${h}:${m}:${s}`;
  };

  const handleButtonClick = () => {
    const url = data.is_button_slug ? data.button_url_slug : data.button_url;
    navigate(url);
  };

  const handleImageClick = () => {
    const url = data.is_button_slug ? data.button_url_slug : data.button_url;
    window.open(url, "_blank");
  };

  if (!data.is_active) {
    return null;
  }

  const PromotionBadge = () =>
    data.is_promotion && <div className={styles.promotionBadge}>Promoted</div>;

  const ContentOverlay = () => (
    <div className={styles.contentOverlay}>
      <h1 className={styles.heading}>{data.title}</h1>
      <p className={styles.para1}>{data.subtitle}</p>
      {data.button_required && (
        <Button
          variant="contained"
          className={styles.button}
          onClick={handleButtonClick}
        >
          {data.button_text}
        </Button>
      )}
    </div>
  );

  const NonImageOnlyContent = () => (
    <>
      <div className={styles.desktopContainer}>
        <div className={styles.left}>
          <h1 className={styles.heading}>{data.title}</h1>
          <p className={styles.para1}>{data.subtitle}</p>
          {data.button_required && (
            <>
              {timer > 0 && (
                <p className={styles.timer}>
                  Offer ends in: {formatTime(timer)}
                </p>
              )}
              <Button
                variant="contained"
                className={styles.button}
                onClick={handleButtonClick}
              >
                {data.button_text}
              </Button>
            </>
          )}
        </div>
        <div className={styles.right}>
          <img src={data.circular_image} alt="Banner Circular" />
        </div>
      </div>
      <div className={styles.mobileContainer}>
        <div className={styles.sliderImg}>
          <img src={data.mobile_image} alt="Mobile Banner" />
          <ContentOverlay />
        </div>
      </div>
    </>
  );

  const ImageOnlyContent = () => (
    <>
      <div className={styles.desktopContainer} onClick={handleImageClick}>
        <div className={styles.fullScreenImage}>
          <img src={data.image_desktop} alt="Desktop Banner" />
        </div>
        <PromotionBadge />
      </div>
      <div className={styles.mobileContainer} onClick={handleImageClick}>
        <div className={styles.sliderImg}>
          <img src={data.image_mobile} alt="Mobile Banner" />
        </div>
        <PromotionBadge />
      </div>
    </>
  );

  return data.is_image_only ? <ImageOnlyContent /> : <NonImageOnlyContent />;
}

export default HomeSLiderData;
