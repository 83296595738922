// import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import butgoLOgo from '../../Assets/butgoLOgo.png'
import styles from './AckModal.module.css'
import CloseIcon from '@mui/icons-material/Close';
import hearts from '../../Assets/hearts.svg'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

 function AckModal({handleClose = () => {},open,data}) {
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
    
  // };
  // console.log(data ,handleClose, open,"data")

  return (
    <React.Fragment  >
      {/* <Button variant="contained" onClick={handleClickOpen}>
        Download
      </Button> */}
      <BootstrapDialog maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}

      >



        <DialogContent className={styles.BootstrapDialog}>
          <div className={styles.container}>
            <div className={styles.close} >
            <CloseIcon onClick={handleClose}/>
            </div>
          
            <div className={styles.about}>
              <div className={styles.img}>
                <img src={data.img}></img>
              </div>
              <div className={styles.text}>
                <span className={styles.head}>{data?.head}</span>
                <p className={styles.desc} dangerouslySetInnerHTML={{ __html: data?.desc }}></p>

              </div>
              <Button variant='contained' onClick={handleClose} className={styles.btn}>Back to Site</Button>
              <div className={styles.image1}>
                <img src={hearts}></img>
              </div>
              <div className={styles.image2}>
                <img src={hearts}></img>
              </div>
            </div>

          </div>
        </DialogContent>

      </BootstrapDialog>
    </React.Fragment>
  );
}

export default AckModal