import React, { useEffect, useState } from "react";
import "./Banner.css";

const Banner = ({
  sellingprice,
  sellingmrp,
  isLive,
  endTime,
  handleClickOpen,
  workshopSlug,
  priceSymbol,
  isUnlimitedSeats,
  seats,
}) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const getTimeLeft = () => {
    const now = new Date().getTime();
    const eighteenHoursInMs = 18 * 60 * 60 * 1000;
    const storedEndTime = localStorage.getItem(`endTime_${workshopSlug}`);

    if (isLive) {
      const eventEndTime = new Date(endTime).getTime();
      const timeLeft = eventEndTime - now;

      if (timeLeft > 0 && timeLeft <= eighteenHoursInMs) {
        return timeLeft;
      } else {
        if (storedEndTime) {
          const timeLeft = storedEndTime - now;

          const storedEndTimeNumber = parseInt(storedEndTime, 10);

          if (timeLeft >= eighteenHoursInMs) {
            startTimer();
            return eighteenHoursInMs;
          } else {
            return timeLeft > 0 ? timeLeft : 0;
          }
        } else {
          return 0;
        }
      }
    } else {
      if (storedEndTime) {
        const timeLeft = storedEndTime - now;

        const storedEndTimeNumber = parseInt(storedEndTime, 10);

        if (timeLeft >= eighteenHoursInMs) {
          startTimer();
          return eighteenHoursInMs;
        } else {
          return timeLeft > 0 ? timeLeft : 0;
        }
      } else {
        return 0;
      }
    }
  };

  const startTimer = () => {
    const now = new Date().getTime();
    const eighteenHoursInMs = 18 * 60 * 60 * 1000;
    const countdownEndTime = now + eighteenHoursInMs;

    localStorage.setItem(
      `endTime_${workshopSlug}`,
      countdownEndTime.toString()
    );

    setTimeLeft(eighteenHoursInMs);
  };

  useEffect(() => {
    let interval;
    if (!isLive) {
      const storedEndTime = localStorage.getItem(`endTime_${workshopSlug}`);
      if (!storedEndTime) {
        startTimer();
      } else {
        const timeLeft = getTimeLeft();
        if (timeLeft === 0) {
          startTimer();
        } else {
          setTimeLeft(timeLeft);
        }
      }
    } else {
      setTimeLeft(getTimeLeft());
    }

    interval = setInterval(() => {
      const timeLeft = getTimeLeft();
      setTimeLeft(timeLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, [isLive, endTime, workshopSlug]);

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const seconds = totalSeconds % 60;
    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;
    const totalHours = Math.floor(totalMinutes / 60);
    const hours = totalHours % 24;
    const days = Math.floor(totalHours / 24);

    return { days, hours, minutes, seconds };
  };

  const { days, hours, minutes, seconds } = formatTime(timeLeft);

  return (
    <div className="banner">
      <div className="banner-content">
        <span className="offer-text">OFFER ENDS IN</span>
        <div className="timer">
          <span className="time">{days}D</span>:
          <span className="time">{hours}H</span>:
          <span className="time">{minutes}M</span>:
          <span className="time">{seconds}S</span>
        </div>
        <div className={"buttonChipContainer"}>
          <button className="register-button" onClick={handleClickOpen}>
            Register now at {priceSymbol}
            {sellingprice}
          </button>
          {isLive && !isUnlimitedSeats && (
            <div className={"seatsChip"}>{seats} seats left</div>
          )}
        </div>

        <span className="special-offer">
          special offer{" "}
          <s>
            {priceSymbol}
            {sellingmrp}
          </s>{" "}
          {priceSymbol}
          {sellingprice} only
        </span>
      </div>
    </div>
  );
};

export default Banner;
