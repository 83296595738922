import React from 'react'
import styles from '../JoinExpert/JoinExpert.module.css'
import { Button } from '@mui/material'
import doctor from '../../Assets/doctor.png'
import doctorMobile from '../../Assets/doctorMobile.png'

function JoinExpert() {
    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <h1>Join us as Expert!</h1>
                <Button className={styles.button} variant="contained">Join us</Button>
            </div>
            <div className={styles.right}>

                <img src={doctor} className={styles.doctor}></img>
                <img src={doctorMobile}className={styles.doctorMobile}></img>
            </div>

        </div>
    )
}

export default JoinExpert