import React, { useEffect, useRef } from "react";
import Hls from "hls.js";
import "./HlsVideoPlayer.css";

const HlsVideoPlayer = ({ currentVideo }) => {
  const videoRef = useRef(null);
  const hlsRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    // Clean up previous HLS instance before creating a new one
    if (hlsRef.current) {
      hlsRef.current.destroy();
      hlsRef.current = null;
    }

    if (currentVideo.url && currentVideo.url.endsWith(".m3u8")) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hlsRef.current = hls;
        hls.loadSource(currentVideo.url);
        hls.attachMedia(video);

        hls.on(Hls.Events.ERROR, (event, data) => {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                console.error(
                  "Network error encountered while loading HLS video."
                );
                hls.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                console.error("Media error encountered in HLS video.");
                hls.recoverMediaError();
                break;
              default:
                hls.destroy();
                break;
            }
          }
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = currentVideo.url;
        video.play();
      }
    } else {
      video.src = currentVideo.url;
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, [currentVideo.url]);

  return (
    <div className="video-player-container">
      <div className="video-aspect-ratio">
        <video
          ref={videoRef}
          controls
          className="video-element"
          controlsList="nodownload"
          playsInline
        />
      </div>
    </div>
  );
};

export default HlsVideoPlayer;
