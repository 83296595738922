import React, { useEffect, useRef, useState } from "react";
import ExpertCards from "../../Components/ExpertCards/ExpertCards";
import Slider from "react-slick";
import SliderImg from "../../Assets/SliderImg.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SliderImgMobile from "../../Assets/SliderImgMobile.png";

import styles from "./index.module.css";
import { Button } from "@mui/material";
import JoinExpert from "../../Components/JoinExpert/JoinExpert";
import HomeDetails from "../../Components/HomeDetails/HomeDetails";
import HomeTabs from "../../Components/HomeTabs/HomeTabs";
import Services from "../../Components/Services/Services";
import ExpertSlider from "../../Components/ExpertSlider/ExpertSlider";
import { open_api, open_api_without_auth } from "../../Utils/network";
import { useLocation } from "react-router-dom";
import ContactUsFrom from "../../Components/ContactUsFrom/ContactUsFrom";
import SliderBase from "../../Components/Slider/SliderBase";
import JoinUsForm from "../../Components/JoinUsForm/JoinUsForm";
import AppPromotion from "../../Components/Card/AppPromotion";
function CustomPrevArrow(props) {
  // console.log(props, "sanjay123")
  const { onClick } = props;
  return (
    <button
      // className="custom-arrow-banner custom-prev-arrow-banner"
      className={`${styles.customarrow} ${styles.customprevarrow}`}
      onClick={onClick}
    >
      <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
    </button>
  );
}

function CustomNextArrow(props) {
  const { onClick } = props;
  return (
    <button
      // className="custom-arrow-banner custom-next-arrow-banner"
      className={`${styles.customarrow} ${styles.customnextarrow}`}
      onClick={onClick}
    >
      <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
    </button>
  );
}

var settings = {
  className: styles.slickstyles,
  // dots: true,
  autoplay: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: false,
  arrows: true,
  // fade: true,
  autoplaySpeed: 2000,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};
function ExpertListingPage() {
  const [expertsList, setExpertsList] = useState([]);
  const location = useLocation();
  // console.log(location?.state?.id , "kdqwjiduhadkaid897" );
  const expertTypeId = location?.state?.id;
  const [page, setPage] = useState(1);
  const [lastElement, setLastElement] = useState(null);
  const [maxpage, setmaxPage] = useState(1);
  const [expertCount, setExpertCount] = useState(0);

  const observer = useRef(
    typeof window != "undefined" &&
      new IntersectionObserver((entries) => {
        const first = entries[0];
        if (first.isIntersecting && page <= maxpage) {
          setPage((no) => no + 1);
        }
      })
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentObserver == false) return;
    if (currentElement) {
      currentObserver.observe(currentElement);
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  useEffect(() => {
    setExpertsList([]);
    setPage(1);
    setmaxPage(1);
  }, [expertTypeId]);

  useEffect(() => {
    if (page === 1) {
      getExperts(expertTypeId, page, true);
    } else if (page <= maxpage) {
      getExperts(expertTypeId, page, false);
    }
  }, [page, expertTypeId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //     if (page <= maxpage) {
  //         getExperts(expertTypeId , page);
  //     }
  //   }, [page, expertTypeId]);

  // useEffect(() => {
  //     getExperts()
  //     // window.scrollTo(0, 0);
  // }, [])
  const getExperts = (id, page, isReset) => {
    open_api_without_auth
      .get(`/profiles/experts?expert_type=${id}&page=${page}`)
      .then((res) => {
        // console.log(res?.data?.data);
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          // setExpertsList(res?.data?.data);
          // setExpertCount(res?.data?.total_count);
          const maxpage = res?.data?.max_pages;
          setmaxPage(maxpage);
          const data = res?.data?.data;
          // console.log(data, maxpage, "checkitsLength");

          if (isReset) {
            setExpertsList(data);
          } else {
            setExpertsList((prevList) => [...prevList, ...data]);
          }
          // setExpertsList(data)
          // setExpertsList((prevList) => [...prevList, ...data]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(list, "listing")
  };

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting && page < maxpage) {
        setPage((no) => no + 1);
      }
    });
    return () => observer.current.disconnect();
  }, [maxpage, page]);

  return (
    <>
      <section>
        <SliderBase page={"Experts"} />
      </section>

      <section>
        <div className={styles.ourExperts}>
          <h1>Our Health Care Experts</h1>
          <div className={styles.buttons}>
            <div className={styles.innerButton} variant="outlined">
              Sibling Rivalry
            </div>
            <div className={styles.innerButton} variant="outlined">
              Behaviour Analysis
            </div>
            <div className={styles.innerButton} variant="outlined">
              Special Needs
            </div>
            <div className={styles.innerButton} variant="outlined">
              Co-Parenting
            </div>
          </div>
        </div>
      </section>

      <section className={styles.expertCards}>
        {expertsList?.map((items) => {
          return <ExpertCards ref={setLastElement} data={items} />;
        })}
      </section>
      <AppPromotion />
      <JoinExpert />

      <JoinUsForm />
    </>
  );
}

export default ExpertListingPage;

const data = [
  { img: SliderImg, id: 1, SliderImgMobile },
  { img: SliderImg, id: 2, SliderImgMobile },
  { img: SliderImg, id: 3, SliderImgMobile },
];
const btns = [
  { id: 1, name: "Sibling Rivalry" },
  { id: 2, name: "Behaviour Analysis" },
  { id: 3, name: "Special Needs" },
  { id: 4, name: "Co-Parenting" },
];
