
// import Link from "next/link";
// import linkImg from "@/Assets/linkImg.png";
// import linkedinImg from "@/Assets/linkedinImg.png";
// import facebookImg from "@/Assets/facebookImg.png";
// import twitterImg from "@/Assets/twitterImg.png";
// import blogsOk from "@/Assets/blogsOk.png";
// import whiteLikeImg from "@/Assets/whiteLikeImg.png";
// import blogsShare from "@/Assets/blogsShare.png";
// import blogsBack from "@/Assets/blogsBack.png";
// import linkShare from "@/Assets/linkShare.png";
// import linkedinShare from "@/Assets/linkedinShare.png";
// import facebookShare from "@/Assets/facebookShare.png";
// import twitterShare from "@/Assets/twitterShare.png";
// import whatsappShare from "@/Assets/whatsappShare.png";
import {useState, useEffect} from "react"
import Button from "@mui/material/Button";
// import Image from "next/image";
import styles from "./share.module.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import share from "../../Assets/forword.png"
// import newshare from "@/Assets/newshare.png"

function Share() {
    const [urlString, setUrlString] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      //for drop-down list(54-61)
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const toIncrease = () => {
      setAnchorEl(null);
    };


    const getCopyText = () => {
        navigator.clipboard.writeText(window.location.href);
        handleClose();
      };

    useEffect(() => {
            setUrlString(window.location.href);
      }, [window.location.href]);

    return (
        <>
   <div>
              <button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className={styles.sharebtn}
              >
                <div>
                  <img src={share} className={styles.mobileShareIcon} alt ="username"/>
                  {/*mobile device*/}
                </div>
              </button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                // onChange={handleChangeCatttttt}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => getCopyText()}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div>
                      {/* <Image src={linkShare} style={{ marginRight: "5px" }} className = {styles.shareImg}/> */}
                    </div>
                    <p>Copy Link</p>
                  </div>
                </MenuItem>

                <MenuItem onClick={() => handleClose()}>
                  <a
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${urlString}`}
                    target="_blank"
                    className={styles.shareLink}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div>
                        {/* <Image
                          src={linkedinShare}
                          style={{ marginRight: "5px" }} className = {styles.shareImg}
                        /> */}
                      </div>
                      <p>Share on LinkedIn</p>
                    </div>
                  </a>
                </MenuItem>

                <MenuItem onClick={() => handleClose()}>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${urlString}`}
                    target="_blank"
                    className={styles.shareLink}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div>
                        {/* <Image
                          src={facebookShare}
                          style={{ marginRight: "5px" }}  className = {styles.shareImg}
                        /> */}
                      </div>
                      <p>Share On Facebook</p>
                    </div>
                  </a>
                </MenuItem>

                <MenuItem onClick={() => handleClose()}>
                  {/* <a href="https://twitter.com/intent/tweet?text=optional%20promo%20text%20http://agrimguru.com/foo.htm?bar=123&baz=456" target="_blank"> */}
                  <a
                    href={`http://www.twitter.com/share?url=${urlString}`}
                    target="_blank"
                    className={styles.shareLink}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div>
                        {/* <Image
                          src={twitterShare}
                          style={{ marginRight: "5px" }}  className = {styles.shareImg}
                        /> */}
                      </div>
                      <p>Share On Twitter</p>
                    </div>
                  </a>
                </MenuItem>
              </Menu>
            </div>


        </>
    )
}

export default Share;