import React from "react";
import styles from "./Services.module.css";
import { Button } from "@mui/material";
import childcare from "../../Assets/childcare.png";
import selfcare from "../../Assets/selfcare.png";
import activities from "../../Assets/activities.png";
import recipes from "../../Assets/recipe.png";
import expert from "../../Assets/expert.png";
import blogs from "../../Assets/blogs.png";
import community from "../../Assets/community.png";
import ServiceData from "../ServiceData/ServiceData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// import experts from '../../Assets/experts.png'

function Services({ text }) {
  function CustomPrevArrow(props) {
    const { onClick } = props;
    return (
      <button
        // className="custom-arrow-banner custom-prev-arrow-banner"
        className={`${styles.customarrow} ${styles.customprevarrow}`}
        onClick={onClick}
      >
        <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
      </button>
    );
  }

  function CustomNextArrow(props) {
    const { onClick } = props;
    return (
      <button
        // className="custom-arrow-banner custom-next-arrow-banner"
        className={`${styles.customarrow} ${styles.customnextarrow}`}
        onClick={onClick}
      >
        <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
      </button>
    );
  }

  var settings = {
    className: styles.slickstyles,
    autoplay: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: false,
    autoplaySpeed: 2000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1688,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <div className={styles.container}>
        {/* <div className={styles.top}>
          <span>{text?.btn}</span>
          <h1 className={styles.servicesh1}>{text.head}</h1>
          <p className={styles.servicesp}>{text.category}</p>
        </div> */}

        <Slider {...settings}>
          {data.map((item) => {
            return <ServiceData data={item} key={item.id} />;
          })}
        </Slider>
      </div>
    </>
  );
}
export default Services;
const data = [
  {
    id: 1,
    img: childcare,
    desc: " Childcare",
    url: "/child-care",
  },
  {
    id: 2,
    img: selfcare,
    desc: " Selfcare",
    url: "",
  },

  { id: 3, img: activities, desc: " Activities", url: "" },
  {
    id: 4,
    img: blogs,
    desc: " Blogs",
    url: "",
  },
  { id: 5, img: community, desc: " Community", url: "" },
  {
    id: 6,
    img: expert,
    desc: " Experts",
    url: "/experts",
  },
  { id: 7, img: recipes, desc: " Recipes", url: "" },
];
