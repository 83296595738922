import React from "react";
import "./OrderCard.css";
import { useNavigate } from "react-router-dom";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const getDayWithSuffix = (day) => {
    if (day > 3 && day < 21) return day + "th";
    switch (day % 10) {
      case 1:
        return day + "st";
      case 2:
        return day + "nd";
      case 3:
        return day + "rd";
      default:
        return day + "th";
    }
  };

  return `${getDayWithSuffix(day)} ${month} ${year}`;
};

const OrderCard = ({
  orderDate,
  total,
  shipTo,
  orderNumber,
  orderedItemTitle,
  orderedItemImage,
  isWorkshop,
  workshopSlug,
  remainingSession,
  order,
}) => {
  const formattedDate = formatDate(orderDate);
  const navigate = useNavigate();
  const handleOrderClick = (id) => {
    navigate(`/profile/orders/${id}`);
  };

  const handleFeedbackLinkClick = () => {
    navigate(`/workshop/${workshopSlug}/feedback`);
  };

  const handleBookRemainingSession = () => {
    navigate(
      `/bookings/${order.session_purchase.expert_info.slug}/details?packageId=${order.session_purchase.package.id}`,
      {
        state: {
          bypassPurchase: true,
          bypassPackage: true,
          bookedPackageId: order.session_purchase.package.id,
          purchaseId: order.session_purchase.id,
          purchasedUserId: order.session_purchase.user_detail.id,
          duration: order.session_purchase.package.slot_duration.duration,
          isDataRequired: order.session_purchase.package.is_user_data_required,
          remainingSession: order.session_purchase.remaining_sessions,
        },
      }
    );
  };

  return (
    <div className="order-summary">
      <div className="order-header">
        <div className="order-info">
          <div>ORDER PLACED</div>
          <div>{formattedDate}</div>
        </div>
        <div className="order-info">
          <div>TOTAL</div>
          <div>{total}</div>
        </div>
        {/* <div className="order-info">
          <div>PURCHASED BY</div>
          <div>{shipTo}</div>
        </div> */}
        <div className="order-info">
          <div>ORDER # {orderNumber}</div>
          <div className="order-links">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleOrderClick(orderNumber);
              }}
            >
              View order details
            </a>
          </div>
        </div>
      </div>

      <div className="order-content">
        <div className="product-info">
          {isWorkshop && (
            <>
              <img
                src={orderedItemImage}
                alt={orderedItemTitle}
                className="product-image"
              />
            </>
          )}

          <div className="product-title-info">
            <div className="product-name">{orderedItemTitle}</div>
            {!isWorkshop && (
              <>
                {remainingSession != null && remainingSession != 0 ? (
                  <>
                    <div className="product-name-subtitle">
                      Remaining Sessions: {remainingSession}
                    </div>
                  </>
                ) : null}
              </>
            )}
          </div>
        </div>
        <div className="action-buttons">
          <button
            className="view-order-button"
            onClick={() => handleOrderClick(orderNumber)}
          >
            View Order Details
          </button>
          {!isWorkshop && (
            <>
              {remainingSession != null && remainingSession != 0 ? (
                <>
                  <button
                    className="book-remaining-session-button"
                    onClick={() => handleBookRemainingSession()}
                  >
                    Book Remaining sessions
                  </button>
                </>
              ) : null}
            </>
          )}

          {isWorkshop && (
            <>
              <button
                className="feedback-order-button"
                onClick={() => handleFeedbackLinkClick()}
              >
                Leave feedback
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
