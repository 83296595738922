import React, { useState, useEffect } from "react";
import styled from "styled-components";

export const TimeContainer = styled.div`
  display: flex;
  gap: 5px;
  color: black;
`;

export const Timespan = styled.span`
  background-color: #b388ff;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  width: 50px;
  text-align: center;
  color: white;
`;

const UpcomingTimer = ({
  targetDate,
  targetTime,
  onEnableButton,
  sessionId,
}) => {
  const calculateTimeLeft = () => {
    const target = new Date(`${targetDate}T${targetTime}`);
    const now = new Date();
    const difference = target - now;

    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return { timeLeft, difference };
  };

  // Initial calculation before rendering
  const initialCalculation = calculateTimeLeft();
  const initialIsTimerVisible =
    initialCalculation.difference <= 24 * 60 * 60 * 1000;

  const [timeLeft, setTimeLeft] = useState(initialCalculation.timeLeft);
  const [isTimerVisible, setIsTimerVisible] = useState(initialIsTimerVisible);
  const [daysLeft, setDaysLeft] = useState(initialCalculation.timeLeft.days);

  useEffect(() => {
    const timer = setInterval(() => {
      const { timeLeft, difference } = calculateTimeLeft();
      setTimeLeft(timeLeft);

      if (difference <= 30 * 60 * 1000) {
        onEnableButton(true);
      } else {
        onEnableButton(false);
      }

      if (difference <= 24 * 60 * 60 * 1000) {
        setIsTimerVisible(true);
      } else {
        setIsTimerVisible(false);
        setDaysLeft(timeLeft.days);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate, targetTime]);

  return (
    <TimeContainer>
      {isTimerVisible ? (
        <>
          <Timespan>{timeLeft.days}D</Timespan>:
          <Timespan>{timeLeft.hours}H</Timespan>:
          <Timespan>{timeLeft.minutes}M</Timespan>:
          <Timespan>{timeLeft.seconds}S</Timespan>
        </>
      ) : (
        <span>
          {daysLeft} {daysLeft === 1 ? "day" : "days"}
        </span>
      )}
    </TimeContainer>
  );
};

export default UpcomingTimer;
