import logo from "./logo.svg";
import "./App.css";
import Mainroutes from "./Approuter/MainRouter";
import theme from "./Utils/theme";
import { ThemeProvider } from "@mui/material/styles";
import { PopupProvider } from "../src/Components/PopUp/PopupContext";
import PopupDialog from "../src/Components/PopUp/PopupDialog";
import { DialogProvider } from "./Components/Dialog/DialogContext";
function App() {
  const currentSlug = window.location.pathname.split("/").pop();
  const currentPath = window.location.pathname;
  const hidePatterns = [/^\/login$/, /^\/[^/]+\/ebook\/download\/[^/]+$/];
  const isExcluded = hidePatterns.some((pattern) => pattern.test(currentPath));

  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>

    // </div>
    // <LandingPage />
    <ThemeProvider theme={theme}>
      <DialogProvider>
        <PopupProvider>
          <div className="App">
            <Mainroutes />
            {!isExcluded && <PopupDialog type={currentSlug} />}
          </div>
        </PopupProvider>
      </DialogProvider>
    </ThemeProvider>
  );
}

export default App;
