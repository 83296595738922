import React from "react";
import "./BookingExpertAbout.css";

const BookingExpertAbout = ({ about, isPackage, aboutPackage }) => {
  return (
    <div className="expert-about">
      {isPackage ? (
        <>
          {aboutPackage ? (
            <>
              <div className="expert-about-package">
                <h2>About Package</h2>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {about.about_session_offered ? (
            <>
              <h2>
                About {about?.user?.first_name} {about?.user?.last_name}
              </h2>
            </>
          ) : (
            <></>
          )}
        </>
      )}

      {isPackage ? (
        <>
          <div dangerouslySetInnerHTML={{ __html: aboutPackage }} />
        </>
      ) : (
        <>
          <div
            dangerouslySetInnerHTML={{ __html: about.about_session_offered }}
          />
        </>
      )}
    </div>
  );
};

export default BookingExpertAbout;
