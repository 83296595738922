import { Button, styled } from '@mui/material'
import React from 'react'
import styles from './OfferDtata.module.css'
import OfferImg from '../../Assets/OfferImg.png'
import foots from '../../Assets/foots.png'
import { useNavigate } from 'react-router-dom'
function OfferDtata({data}) {
    const navigate = useNavigate();

    const handleNavigation = (data) => {
        if(data){
            navigate(`/experts` , {state : data})
        }
    }
    return (
        <>
            <div className={styles.container}>

                <div className={styles.cardImg}>
                    <img src={data?.img ? data?.image : OfferImg}></img>
                    <div className={styles.options}>
                        <span className={styles.head}>{data?.title}</span>

                        <Button className={styles.offerbutton} variant='outlined'  onClick={() => handleNavigation(data)}>
                            <img src={foots}></img>
                            Explore   </Button>
                    </div>
                </div>

            </div>
        </>
    )
}

export default OfferDtata