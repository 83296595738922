import React from "react";
import "./SessionsConducted.css";
import { formatDateToReadable, formatTimeTo12Hour } from "../../Dialog/helper";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SessionsConducted = ({ sessions, total_session }) => {
  const navigate = useNavigate();
  const handleJoinClick = (meetingLink) => {
    window.open(meetingLink);
  };
  return (
    <div className="sessions-conducted-container">
      <div className="header-order-details">
        <h3>
          Sessions Info ({sessions?.length || 0}/{total_session || 0})
        </h3>
        {/* <a href="/history" className="view-history-order-details">
          View History
        </a> */}
      </div>
      <div className="sessions-grid-order-details">
        {sessions.length > 0 ? (
          sessions.map((session, index) => (
            <div className="session-card-order-details" key={session.id}>
              <div className="session-number-order-details">
                Session {index + 1}
              </div>
              <div className="session-date-order-details">
                {formatDateToReadable(session.slot.date)}
              </div>
              <div className="session-date-order-details">
                {formatTimeTo12Hour(session.slot.time)}
              </div>
              <div
                className={`session-status-order-details ${session.status === 1 ? "booked" : session.status === 2 ? "completed" : "cancelled"}`}
              >
                {session.status === 1
                  ? "Booked"
                  : session.status === 2
                    ? "Completed"
                    : "Cancelled"}
              </div>
              {session.status === 1 ? (
                <>
                  <Button
                    variant="contained"
                    className="session-conducted-join-button"
                    onClick={() => handleJoinClick(session.jitsi_meeting_link)}
                  >
                    Join
                  </Button>
                </>
              ) : (
                <></>
              )}
            </div>
          ))
        ) : (
          <p>No sessions conducted yet.</p>
        )}
      </div>
    </div>
  );
};

export default SessionsConducted;
