import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import styles from './CustomizedDialogs.module.css'
import back from '../../Assets/back.png'
import pickBaby from '../../Assets/pickBaby.png'
import myBurgologo from '../../Assets/myBurgologo.png'

import qr from '../../Assets/qr.png'
import appSTore from '../../Assets/appSTore.png'
import googlePlay from '../../Assets/googlePlay.png'
import pickBabyMobile from '../../Assets/pickBabyMobile.png'




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment  >
      <Button variant="contained" onClick={handleClickOpen}>
        Download
      </Button>
      <BootstrapDialog maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}

      >



        <DialogContent className={styles.BootstrapDialog}>
          <div className={styles.container}>
            <div className={styles.left}>
              <div className={styles.back} onClick={handleClose}>
                <img src={back}></img>
              </div>

              <div className={styles.pickBaby}>
                <img src={pickBaby}></img>
              </div>
              <div className={styles.pickBabyMobile}>
                <img src={pickBabyMobile}></img>
              </div>
            </div>

            <div className={styles.right}>
              <div className={styles.top}>

                <div className={styles.myBurgologo}>
                  <img src={myBurgologo}></img>
                </div>
                <p className={styles.para}><span>Join India’s</span>  best parenting app </p>
                <button className={styles.btn}>Download Now</button>
              </div>
              <div className={styles.bottom}>
                <div className={styles.bottomleft}>
                  <img src={qr}></img>
                </div>
                <div className={styles.bottomright}>

                  <div>
                    <a href=''>   <img src={appSTore}></img></a>

                  </div>
                  <div>
                    <a href=''>       <img src={googlePlay}></img></a>

                  </div>


                </div>

              </div>
            </div>

          </div>
        </DialogContent>

      </BootstrapDialog>
    </React.Fragment>
  );
}
