import React from "react";
import footer from "../../Assets/footer/footerbg.svg";
import footer2 from "../../Assets/footer/footer2.svg";
import footer2M from "../../Assets/footer/footerbBg.png";
// import footer from "../../assets/footer/footerbg.svg";
import styles from "./footer.module.css";
import iconsfac from "../../Assets/footer/Facebook.svg";
import iconsinsta from "../../Assets/footer/Instgram.svg";
import iconsyoutube from "../../Assets/footer/Youtube.svg";
import iconsloc from "../../Assets/footer/Location.svg";
import iconsmail from "../../Assets/footer/Mail.svg";
import iconscall from "../../Assets/footer/Contact.svg";
import { Link } from "react-router-dom";
function Footer() {
  const handleSocialMediaClick = (link) => {
    window.open(link);
  };
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerLinks}>
        <div className={styles.socialmediaLinks}>
          <div className={styles.socialIcon}>
            <img
              src={iconsfac}
              onClick={() =>
                handleSocialMediaClick(
                  "https://www.facebook.com/profile.php?id=61565026686788"
                )
              }
            />

            <Link
              className={`${styles.plink} ${styles.hidemobile}`}
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
          </div>
          <div className={styles.socialIcon}>
            <img
              src={iconsinsta}
              onClick={() =>
                handleSocialMediaClick(
                  "https://www.instagram.com/myburgo_app?igsh=dzR3OHkxbG9kaXBu"
                )
              }
            />

            <Link
              className={`${styles.plink} ${styles.hidemobile}`}
              to="/terms-and-condition"
            >
              Terms And Condition
            </Link>
          </div>
          <div className={styles.socialIcon}>
            <img
              src={iconsyoutube}
              onClick={() =>
                handleSocialMediaClick(
                  "https://youtube.com/@myburgo_app?si=7uSggIhoSZOBMppR"
                )
              }
            />
            <Link
              className={`${styles.plink} ${styles.hidemobile}`}
              to="/cancellation-and-refund-policy"
            >
              Cancellation and Refund Policy
            </Link>
          </div>
        </div>
        <div className={styles.countactUs}>
          <div className={styles.footerBox}>
            <div className={styles.footerImgC}>
              <img src={iconscall} />
            </div>
            <div>
              <h6>Phone Number</h6>
              <h5>
                <a href={`tel: +91 7710048212`}>+91 7710048212</a>
              </h5>
              {/* <h5>+91 7710048212</h5> */}
            </div>
          </div>
          <div className={styles.footerBox}>
            <div className={styles.footerImgC}>
              <img src={iconsmail} />
            </div>
            <div>
              <h6>Email Address</h6>
              <h5>
                <a href={`mailto: hello@myburgo.com`}>hello@myburgo.com</a>
              </h5>
              {/* <h5>support@gmial.com</h5> */}
            </div>
          </div>
          <div className={styles.footerBox}>
            <div className={styles.footerImgC}>
              <img src={iconsloc} />
            </div>
            <div>
              <h6>Our Address</h6>
              <h5>
                B-303, Mangalya, Marol Maroshi Road, Andheri ( East ),
                Mumbai-400059 India.
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.pageLink}>
        <Link className={styles.plink} to="/privacy-policy">
          Privacy Policy
        </Link>
        <Link className={styles.plink} to="/terms-and-condition">
          Terms And Condition
        </Link>
        <Link className={styles.plink} to="/cancellation-and-refund-policy">
          Cancellation and Refund Policy
        </Link>
      </div>

      <div className={styles.footerbottom}>
        <img src={footer2} />
      </div>
      <div className={styles.fBformob}>
        <img src={footer2M} />
      </div>
      <div className={styles.copyrighttext}>
        © copyright by Velvin Technologies Pvt Ltd All rights Reserved
      </div>
    </div>
  );
}

export default Footer;
