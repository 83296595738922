// src/Components/PopupContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const popupSeen = sessionStorage.getItem("popupSeen");
    if (!popupSeen) {
      const timer = setTimeout(() => {
        setIsOpen(true);
        sessionStorage.setItem("popupSeen", "true");
      }, 10000); // Show popup after 10 seconds

      return () => clearTimeout(timer);
    }
  }, []);

  const showPopup = () => setIsOpen(true);
  const hidePopup = () => setIsOpen(false);

  return (
    <PopupContext.Provider value={{ isOpen, showPopup, hidePopup }}>
      {children}
    </PopupContext.Provider>
  );
};

export const usePopup = () => {
  return useContext(PopupContext);
};
