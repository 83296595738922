import React from "react";
import styles from "./ExpertData.module.css";
import circle from "../../Assets/course_page/circular.svg";
import { useNavigate } from "react-router-dom";

function ExpertData({ item }) {
  const navigate = useNavigate();

  // const sendtodetalis = (url) => {
  //     handaleNavigation(url)
  // }
  const handleNavigate = (url) => {
    if (url) {
      navigate(`/experts/${url}`);
    }
  };

  return (
    <div
      className={styles.container}
      onClick={() => handleNavigate(item?.slug)}
    >
      <div className={styles.img}>
        <img src={circle} className={styles.bgCircle} />
        <img className={styles.expertImgCircle} src={item.image}></img>
      </div>
      <h1>
        {item.first_name} {item.last_name}
      </h1>
      {item.specialist_tagline ? <p>{item.specialist_tagline}</p> : ""}
    </div>
  );
}

export default ExpertData;
