import React from "react";
import styles from "./Recommended.module.css";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
function Recommended() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.topic}>
          <h1 className={styles.h1}>Recommend a topic</h1>
          <TextField variant="outlined" placeholder="Enter topic Name" />
          <Button className={styles.recbutton} variant="outlined">
            SUBMIT
          </Button>
        </div>
      </div>
      <div className={styles.mobileContainer}>
        <div className={styles.topic}>
          <h1 className={styles.h1}>Recommend a topic</h1>
          <div className={styles.bottom}>
            <TextField variant="outlined" placeholder="Enter topic Name" />
            <Button className={styles.recbutton} variant="outlined">
              SUBMIT
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Recommended;
