// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDq2x-83amT3r3L8qlxstUOSHOUWDPOsaM",
  authDomain: "myburgo-c21c9.firebaseapp.com",
  projectId: "myburgo-c21c9",
  storageBucket: "myburgo-c21c9.appspot.com",
  messagingSenderId: "668704921942",
  appId: "1:668704921942:web:7b072bbea8a76c0724def7",
  measurementId: "G-DVPMQNXJFF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: "select_account ",
});
export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
