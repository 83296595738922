import styles from "./HomeDetailsData.module.css";
import React from "react";
function HomeDetailsData({ item }) {
  return (
    <div className={styles.container}>
      <div>
        <img src={item.img}></img>
      </div>
      <div className={styles.info}>
        <span>{item.num}</span>
        <p>{item.text}</p>
      </div>
    </div>
  );
}
export default HomeDetailsData;
