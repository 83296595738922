// src/Components/Profile/OrderDetails.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { open_api_with_user_token } from "../../../Utils/network";
import LoadingOverlay from "../../Dialog/LoadingOverlay";
import OrderDetailsWorkshop from "./OrderDetailsWorkshop";
import OrderDetailsSession from "./OrderDetailsSession";

const OrderDetails = () => {
  const { id } = useParams(); // Get the order ID from the route params
  const [orderDetails, setOrderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isWorkshop, setIsWorkshop] = useState(true);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        setIsLoading(true);
        const token = localStorage.getItem("bearerToken");
        const response = await open_api_with_user_token(token).get(
          `/payment/history/${id}` // Replace with your actual API endpoint
        );
        setOrderDetails(response.data);
        if (response.data.workshop_purchase === null) {
          setIsWorkshop(false);
        }
      } catch (error) {
        console.error("Failed to fetch order details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id]);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (!orderDetails) {
    return <div>No order details found.</div>;
  }

  return (
    <>
      {isWorkshop ? (
        <OrderDetailsWorkshop orderDetails={orderDetails} />
      ) : (
        <OrderDetailsSession orderDetails={orderDetails} orderId={id} />
      )}
    </>
  );
};

export default OrderDetails;
