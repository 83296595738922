import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import LoginDialog from "../Login/LoginCard";
import "./Layout.css";

function Layout() {
  const location = useLocation();
  const navigate = useNavigate();

  const shouldHideHeaderFooter = () => {
    const hidePatterns = [/^\/login$/, /^\/[^/]+\/ebook\/download\/[^/]+$/];
    return hidePatterns.some((pattern) => pattern.test(location.pathname));
  };

  const handleLoginSuccess = (userName) => {
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="layout-container">
      {/* Conditionally render header and footer based on the current path */}
      {!shouldHideHeaderFooter() && (
        <Header handleLoginSuccess={handleLoginSuccess} />
      )}

      <main className="content">
        <Outlet />
      </main>
      <a
        href="https://api.whatsapp.com/send?phone=917710048212&text=Hi%20can%20you%20please%20help%20me%20with%20..."
        className="whatsapp-float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-whatsapp my-float"></i>
      </a>
      {!shouldHideHeaderFooter() && <Footer />}
    </div>
  );
}

export default Layout;
