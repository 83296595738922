import React, { useState, useEffect } from "react";
import styles from "./ExpertSection.module.css";
import star from "../../Assets/course_page/star.svg";
import message from "../../Assets/course_page/message.svg";
import people from "../../Assets/course_page/people.svg";
import play from "../../Assets/course_page/play_circle.svg";
import circular from "../../Assets/course_page/circular.svg";
import { Button } from "@mui/material";
import BookSessionDialog from "../Dialog/BookSessionDialog";
import { open_api, open_api_without_auth } from "../../Utils/network";
import { useNavigate } from "react-router-dom";

const ExpertSection = ({ expertIds }) => {
  const [open, setOpen] = useState(false);
  const [selectedExpert, setSelectedExpert] = useState(null);
  const [expertDetailsList, setExpertDetailsList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (expertIds && expertIds.length > 0) {
      fetchExpertDetails(expertIds);
    }
  }, [expertIds]);

  const fetchExpertDetails = async (expertIds) => {
    try {
      const expertDetailsPromises = expertIds.map((id) =>
        open_api.get(`/profiles/expert/${id}`)
      );

      const expertsResponse = await Promise.all(expertDetailsPromises);
      let expertsData = await Promise.all(
        expertsResponse.map(async (res) => {
          const expert = res.data;
          const packages = await fetchPackages(expert.id);
          const showcase = await fetchShowcase(expert.id);
          return { ...expert, packages, showcase };
        })
      );
      expertsData = expertsData.sort(() => Math.random() - 0.5);
      //console.log("Fetched and randomized expert data:", expertsData);
      setExpertDetailsList(expertsData);
    } catch (error) {
      console.error("Error fetching expert details:", error);
    }
  };

  const fetchPackages = async (expertId) => {
    try {
      const response = await open_api.get(
        `/session/package_list?expert=${expertId}`
      );
      //console.log("Fetched packages for expert ID", expertId, response);
      return response?.data?.data || [];
    } catch (error) {
      console.error("Error fetching packages:", error);
      return [];
    }
  };

  const fetchShowcase = async (id) => {
    try {
      const response = await open_api_without_auth.get(
        `profiles/experts/showcase?expert=${id}`
      );
      return response?.data?.results || [];
    } catch (error) {
      console.error("Error fetching expert showcase:", error);
      return [];
    }
  };

  const handleClickOpen = (expert) => {
    navigate(`/bookings/${expert.slug}`);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getIcon = (key) => {
    const lowerKey = key.toLowerCase();

    if (lowerKey.includes("rating")) {
      return star;
    } else if (lowerKey.includes("review")) {
      return message;
    } else if (lowerKey.includes("parent") || lowerKey.includes("student")) {
      return people;
    } else if (lowerKey.includes("course")) {
      return play;
    } else {
      return ""; // Default or placeholder image
    }
  };

  return (
    <div className={styles.expertContainer}>
      {expertDetailsList.map((expertDetails, index) => (
        <div key={index} className={styles.singleExpertSection}>
          <div className={styles.expertImageSection}>
            <img
              src={circular}
              className={styles.svgBackground}
              alt="background"
            />
            <img
              src={expertDetails?.image}
              alt={expertDetails?.slug}
              className={styles.expertImage}
            />
            <h3>
              {expertDetails?.title} {expertDetails?.user?.first_name}{" "}
              {expertDetails?.user?.last_name}
            </h3>
            <p>{expertDetails?.specialist_tagline}</p>
          </div>
          <div className={styles.expertDetails}>
            <h2>Meet your expert</h2>
            <ul className={styles.stats}>
              {expertDetails?.showcase?.map((v, i) => (
                <li key={i}>
                  <img src={getIcon(v.key)} alt={v.key} />
                  {v.value} {v.key}
                </li>
              ))}
            </ul>
            <p>{expertDetails?.about}</p>
            <Button
              variant="contained"
              className={`${styles.allActionBtn} ${
                expertDetails.packages && expertDetails.packages.length === 0
                  ? styles.greyBtn
                  : ""
              }`}
              onClick={() => handleClickOpen(expertDetails)}
              disabled={
                expertDetails.packages && expertDetails.packages.length === 0
              }
            >
              Book 1 on 1 session
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExpertSection;
