import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import styles from "../../Pages/CourseDetailsPage/index.module.css";
import topRightCircular from "../../Assets/course_page/topRightCircular.svg";

const VideoPlayer = ({ category1Media, category2Media }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [isTabVisible, setIsTabVisible] = useState(true);

  const handleVideoClick = () => {
    setIsMuted(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && isTabVisible) {
            setIsPlaying(true);
          } else {
            setIsPlaying(false);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [isTabVisible]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setIsTabVisible(false);
        setIsPlaying(false);
      } else {
        setIsTabVisible(true);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <div className={styles.containerRight}>
      {category1Media ? (
        <div
          ref={videoRef}
          onClick={handleVideoClick}
          style={{ position: "relative", cursor: "pointer" }}
        >
          <ReactPlayer
            key={category1Media.id}
            controls={false}
            playing={isPlaying && isTabVisible}
            muted={isMuted}
            loop={true}
            url={category1Media.media}
            playsinline={true}
            className={styles.innerVideoDisplay}
            style={{
              margin: "0 auto",
              backgroundColor: "#000",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          />
          {isMuted && (
            <div
              className={styles.unmuteIcon}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#fff",
                fontSize: "17px",
                padding: "10px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            >
              🔊 Click to Unmute
            </div>
          )}
        </div>
      ) : category2Media ? (
        <div key={category2Media.id} className={styles.topRightImageContainer}>
          <img
            src={topRightCircular}
            alt="Circular Ring"
            className={styles.circularRing}
          />
          <img
            src={category2Media.media}
            alt={category2Media.title}
            className={styles.circularImage}
          />
        </div>
      ) : (
        <div>No media available</div>
      )}
    </div>
  );
};

export default VideoPlayer;
