import React, { useEffect, useState } from "react";
import ClientreviewCard from "../ClientreviewCard/ClientreviewCard";
import { open_api } from "../../Utils/network";

function ExpertReview({ data }) {
  return (
    <>
      <ClientreviewCard data={data} />
    </>
  );
}

export default ExpertReview;
