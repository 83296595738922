import React from 'react'
import styles from './Types.module.css'
import depression from '../../Assets/depression.png'
import arrowBtn from '../../Assets/arrowBtn.png'
import mobileDepression from '../../Assets/mobileDepression.png'


function Types() {
    return (<>
        <div className={styles.desktop}>
            <div className={styles.types}>

                <div className={styles.left}>
                    <img src={depression}></img>
                </div>
                <div className={styles.middle}>
                    <div>

                        <h1 className={styles.typesh1}>Depression</h1>
                        <p className={styles.typesp}>There's a lot that you can do on your own
                            to ease the symptoms of depression.
                            Changing your lifestyle can have a big
                            effect on your mood....</p>
                    </div>

                    <div className={styles.right}>
                        <img src={arrowBtn}></img>
                    </div>
                </div>
            </div>

        </div>
        <div className={styles.mobile}>


            <div className={styles.types}>

                <div className={styles.left}>
                    <img src={mobileDepression}></img>
                </div>
                <div className={styles.middle}>
                    <div className={styles}>

                        <h1>Depression</h1>
                        <p>There's a lot that you can do on your own
                            to ease the symptoms of depression.
                            Changing your lifestyle can have a big
                            effect on your mood....</p>
                    </div>

                    <div className={styles.right}>
                        <img src={arrowBtn}></img>
                    </div>
                </div>
            </div>
        </div>








    </>)
}

export default Types