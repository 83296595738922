import React from "react";
import styles from "./index.module.css";
import cardimg from "../../Assets/expert_page/spCardImg.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function CustomPrevArrow(props) {
  const { onClick } = props;
  return (
    <button
      // className="custom-arrow-banner custom-prev-arrow-banner"
      className={`${styles.customarrow} ${styles.customprevarrow}`}
      onClick={onClick}
    >
      <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
    </button>
  );
}

function CustomNextArrow(props) {
  const { onClick } = props;
  return (
    <button
      // className="custom-arrow-banner custom-next-arrow-banner"
      className={`${styles.customarrow} ${styles.customnextarrow}`}
      onClick={onClick}
    >
      <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
    </button>
  );
}

var settings = {
  className: styles.slickstyles,
  // dots: true,
  autoplay: true,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  adaptiveHeight: false,
  arrows: false,
  // fade: true,
  autoplaySpeed: 3000,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1140,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

function SpecialitiesCard({ data }) {
  return (
    <>
      <Slider {...settings}>
        {data?.map((v, i) => {
          return (
            <>
              <div className={styles.cardConatiner}>
                <div className={styles.imgConatiner}>
                  <img src={v?.image} alt="" />
                </div>
                <p className={styles.descript}>
                  {/* Why do children cry in young age? */}
                  {v?.title}
                </p>
              </div>
            </>
          );
        })}
      </Slider>
    </>
  );
}

export default SpecialitiesCard;

const carddetails = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
];
