import React, { useState } from "react";
import { Star } from "lucide-react";
import styles from "./CoachingCard.module.css";
import { useNavigate } from "react-router-dom";
import sessionPackage from "../../Assets/sessionPackage.svg";

const CoachingCard = ({
  rating,
  tag,
  title,
  expertSlug,
  subtitle,
  duration,
  sessionType,
  priceSymbol,
  price,
  sessionCount,
  originalPrice,
  discount,
  packageId,
}) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleReadMore = () => {
    setIsExpanded((prev) => !prev);
  };

  const truncateRichText = (html, wordLimit) => {
    const div = document.createElement("div");
    div.innerHTML = html;

    const words = div.textContent.split(" ");
    if (words.length <= wordLimit) return html;

    const truncatedText = words.slice(0, wordLimit).join(" ") + "...";
    div.textContent = truncatedText;

    return div.innerHTML;
  };

  return (
    <div className={styles.coachingCard}>
      {/* Header Section */}
      <div className={styles.cardHeader}>
        <div className={styles.rating}>
          <Star className={styles.starIcon} />
          <span>{rating}/5</span>
        </div>
        {tag && <span className={styles.tag}>{tag}</span>}
      </div>

      {/* Title */}
      <h2 className={styles.title}>{title}</h2>

      {/* Subtitle with "Read more" functionality */}
      {subtitle && (
        <div className={styles.subtitle}>
          <div
            dangerouslySetInnerHTML={{
              __html: isExpanded ? subtitle : truncateRichText(subtitle, 15),
            }}
          />
          {subtitle.split(" ").length > 15 && (
            <span
              className={styles.readMore}
              onClick={handleToggleReadMore}
              style={{
                cursor: "pointer",
                color: "#2563eb",
                marginLeft: "5px",
              }}
            >
              {isExpanded ? "Show less" : "Read more"}
            </span>
          )}
        </div>
      )}

      {/* Footer Section */}
      <div className={styles.cardFooter}>
        <div className={styles.sessionInfo}>
          <img src={sessionPackage} alt="Session Info" />
          <div className={styles.sessionInfoFooter}>
            {sessionCount === 1 ? (
              <>
                <span>{duration}</span>
                <span className={styles.sessionType}>{sessionType}</span>
              </>
            ) : (
              <span>{sessionCount} sessions</span>
            )}
          </div>
        </div>

        {/* Price Section */}
        <div className={styles.priceSectionRight}>
          {discount != 0 ? (
            <>
              <div className={styles.discountSection}>
                <s className={styles.originalPrice}>
                  {priceSymbol}
                  {originalPrice}
                </s>
                <span className={styles.discount}>{discount}% off</span>
              </div>
            </>
          ) : (
            <></>
          )}

          <div
            className={styles.priceButton}
            onClick={() =>
              navigate(`/bookings/${expertSlug}/details?packageId=${packageId}`)
            }
          >
            <span>
              {priceSymbol}
              <b>{price}</b>
            </span>
            <svg
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachingCard;
