import React from "react";
import styles from "./AppPromotion.module.css";
import googlePlayIcon from "../../Assets/login/googleplay.svg"; // Replace with your actual Google Play icon path
import appStoreIcon from "../../Assets/login/applestore.svg"; // Replace with your actual App Store icon path
import appScreens from "../../Assets/login/appScreen.png"; // Replace with your actual app screens image path

const AppPromotion = () => {
  return (
    <div className={styles.promotionContainer}>
      <div className={styles.textSection}>
        <h1>
          Download the <span className={styles.highlight}>Myburgo</span> Mobile
          app today
        </h1>
        <div className={styles.storeButtons}>
          <a
            href="https://play.google.com/store/apps/details?id=com.my.burgo&pcampaignid=web_share"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.storeLink}
          >
            <img
              src={googlePlayIcon}
              alt="Google Play"
              className={styles.storeIcon}
            />
          </a>
          <a
            href="https://apps.apple.com/in/app/myburgo/id6736937593"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.storeLink}
          >
            <img
              src={appStoreIcon}
              alt="App Store"
              className={styles.storeIcon}
            />
          </a>
        </div>
      </div>
      <div className={styles.imageSection}>
        <img src={appScreens} alt="App Screens" className={styles.appScreens} />
        <p className={styles.tagline}>
          India’s Best Experts Under One Roof
          <br />
          <span>Because we care for you.</span>
        </p>
      </div>
    </div>
  );
};

export default AppPromotion;
