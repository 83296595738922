import React from "react";
import styles from "./CareDetails.module.css";
import dummy from "../../Assets/dummy.png";
import { useNavigate } from "react-router-dom";

function CareDetails({ data }) {
  const navigate = useNavigate();

  // console.log(data, "098989");

  const handlenavigate = (slug) => {
    navigate(`/workshop/${slug}`);
  };
  return (
    <div
      className={styles.container}
      onClick={() => handlenavigate(data?.slug)}
    >
      <div className={styles.top}>
        <img src={data.file ? data?.file : dummy}></img>
      </div>
      <div className={styles.bottom}>
        <h1>{data.title}</h1>
        <div>
          <p>
            {data?.workshop_experts[0]?.first_name}{" "}
            {data?.workshop_experts[0]?.last_name}
          </p>
          <p>{data?.workshop_experts[0]?.specialist_tagline}</p>
        </div>
      </div>
    </div>
  );
}
export default CareDetails;
