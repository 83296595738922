import React, { useState } from 'react'
import styles from './index.module.css'
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import mapIMg from "../../Assets/Logo_icons/mapImgdemo.png";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { open_api_without_auth } from "../../Utils/network";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AckModal from '../AckModal/AckModal';
import thankU from '../../Assets/thankU.png'
import butgoLOgo from '../../Assets/butgoLOgo.png'

function ContactUsFrom() {
    const [contactInfo, setContactInfo] = useState([]);
  const [formData, setFormData] = useState({
    // first_name: "",
    // last_name: "",
    name: "",
    email: "",
    contact_number: "",
    // subject: "",
    message: "",
    // link:"",
  });

  const [fromError, setFromError] = useState({
    name : ``,
      email: "",
      contact_number : "",
      join_as: "",
      link: "",
      skills: "",
      message:"",
  })

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [linkError, setLinkError] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    // const fieldValue = value
    //   type === "radio" ? value : type === "checkbox" ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Reset validation errors when user starts typing
    if (name === "name") {
      setFirstNameError(false);
    } else  if (name === "email") {
      setEmailError(false);
    } else if (name === "contact_number") {
    //   setPhoneError(false);
    if (phoneRegex.test(value)) {
        setPhoneError(false);
      } else {
        setPhoneError(true);
      }
    }
  };

   // Regular expressions for phone number and email validation
   const phoneRegex = /^[0-9]{10}$/; // Assumes a 10-digit phone number format
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//    const linksRegex = /^(https?:\/\/)?(www\.)?(facebook|twitter|linkedin|instagram|youtube)\.com\/[a-zA-Z0-9(\.\?)?]+$/;
   const handleSubmit = (event) => {
     event.preventDefault();
     // Perform form submission or other actions with the form data
     setFirstNameError(false);
    //  setLastNameError(false);
     setEmailError(false);
     setPhoneError(false);
    //  setLinkError(false)
 
     // Validate required fields
     if (formData.name === "") {
       setFirstNameError(true);
       return;
     }
    //  if (formData.last_name === "") {
    //    setLastNameError(true);
    //    return;
    //  }
     if (formData.email === "" || !emailRegex.test(formData.email)) {
       setEmailError(true);
       return;
     }
     if (formData.contact_number === "" || !phoneRegex.test(formData.contact_number)) {
       setPhoneError(true);
       return;
     }
     
    //  if (formData.link === "" || !linksRegex.test(formData.link)){
    //    setLinkError(true);
    //    return;
    //  }
 
     const data = {
       name : formData?.name,
       email: formData?.email,
       contact_number : formData?.contact_number,
       
       message: formData?.message,
     }
    //  console.log(data, "posthit");
     // api_call.post("base/v1/contact_us/", formData)
 
     //  // Clear the form after submission
     //  setFormData({
     //   first_name: "",
     //   last_name: "",
     //   email: "",
     //   phone: "",
     //   subject: "",
     //   message: "",
     // });
 
     // Reset the error state variables
     open_api_without_auth
      .post(`home/contact_us/`, data)
      .then((res) => {
      //  console.log(res);
      //  if(res?.status === 400){
      //    setFromError({
      //      ...fromError,
      //      link: res?.data?.social_media_link
      //    })
      //  }
        if(res?.status === 200 || res?.status === 201 || res?.status === 204 ){
          setFormData({
            name: "",
            email: "",
            contact_number: "",
            message: "",
          });
          handleClick();
        }
      })
      .catch((err) => {
       console.log(err);
      })
     setFirstNameError(false);
    
     setEmailError(false);
     setPhoneError(false);
   };
  return (
    <section className={styles.contactInfoContainer}>
        <h2 className={styles.heading}>CONTACT US</h2>
      <div className={styles.contactuseForm}>
        <div className={styles.leftContainer}>
          <div className={styles.iconText}>
            <span><LocationOnIcon /></span> <strong> LOCATION:-</strong> B-303, Mangalya, Marol Maroshi Road, Andheri (East),
            Mumbai-400059 India.
          </div>
          <div className={styles.iconText}>
          <span> <EmailIcon /></span> <strong> EMAIL:-</strong> <a className={styles.joinusLink} href={`mailto: customercare@myburgo.com`}>
                  customercare@myburgo.com
                </a>
          </div>
          <div className={styles.iconText}>
          <span> <LocalPhoneIcon /></span> <strong> CALL:-</strong> <a className={styles.joinusLink} href={`tel: +91 7710048212`}>+91 7710048212</a>
          </div>
          <div className={styles.mapContainer}>
            <img src={mapIMg} />
          </div>
        </div>
        <div className={styles.rightContainer}>
          <form >
            <div className={styles.inputFields}>
              <TextField
                name="name"
                label="Name"
                value={formData.name}
                onChange={handleChange}
                variant="filled"
                required
                error={firstNameError}
                helperText={firstNameError ? "First Name is required" : ""}
                sx={{ width: "100%", }}
                
              />
            </div>
            {/* <div className={styles.inputFields}>
              <TextField
                name="last_name"
                label="Last Name"
                value={formData.last_name}
                onChange={handleChange}
                // required
                variant="filled"
                error={lastNameError}
                helperText={lastNameError ? "Last Name is required" : ""}
                sx={{ width: "100%" }}
              />
            </div> */}
            <div className={styles.inputFields}>
              <TextField
                name="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                variant="filled"
                required
                error={emailError}
                helperText={emailError ? "Email is required" : ""}
                sx={{ width: "100%" }}
              />
            </div>
            <div className={styles.inputFields}>
              <TextField
                name="contact_number"
                label="Phone Number"
                value={formData.contact_number}
                onChange={handleChange}
                variant="filled"
                required
                inputProps={{maxLength : 10}}
                error={phoneError}
                helperText={phoneError ? "Phone Number is required" : ""}
                sx={{ width: "100%" }}
              />
            </div>
            {/* <div className={styles.inputFields}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Select Subject
              </FormLabel>
              <RadioGroup
                row
                name="subject"
                aria-labelledby="demo-radio-buttons-group-label"
                value={formData.subject}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Content Creator"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Experts"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="Blogger"
                />
                
              </RadioGroup>
            </div> */}
            {/* <div className={styles.inputFields}>
              <TextField
                name="link"
                label="Social Media Link"
                value={formData.link}
                onChange={handleChange}
                variant="filled"
                // required
                error={linkError}
                helperText={linkError ? "Enter your social media link" : ""}
                sx={{ width: "100%" }}
              />
            </div>
            <div className={styles.inputFields}>
              <TextField
                name="skills"
                label="SKILLS"
                value={formData.skills}
                onChange={handleChange}
                variant="filled"
                // required
                // error={phoneError}
                // helperText={phoneError ? "Phone Number is required" : ""}
                sx={{ width: "100%" }}
              />
            </div> */}
            <div className={styles.inputFields}>
              <TextField
                name="message"
                label="Message"
                multiline
                rows={6}
                value={formData.message}
                onChange={handleChange}
                variant="filled"
                sx={{ width: "100%" }}
              />
            </div>
            <div className={styles.inputFields}></div>
            <Button onClick={handleSubmit} variant="contained"className={styles.submit}>SUBMIT</Button>
          </form>
        </div>
      </div>
      {/* <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Your From filled Successfully!
        </Alert>
      </Snackbar> */}
          <AckModal handleClose={handleClose} data={data} open={open}/>

    </section>
  )
}

export default ContactUsFrom
const data = 
  {

    img: butgoLOgo,
    head: "Thanks for Joining!",
    desc: "Welcome to our community."
  }