import React, { useEffect } from 'react'
import styles from './MentalHelth.module.css'
import ExpertSlider from '../../Components/ExpertSlider/ExpertSlider'
import ExpertService from '../../Components/ExpertService/ExpertService'
import Services from '../../Components/Services/Services'
import depression from '../../Assets/depression.png'
import arrowBtn from '../../Assets/arrowBtn.png'
import ExpertWeHave from '../../Components/ExpertWeHave/ExpertWeHave'
import Types from '../../Components/Types/Types'
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from 'react-slick'
import WorkshopCard from '../../Components/WorkshopCard/WorkshopCard'
import ExpertDetailsWorkshop from '../../Components/ExpertDetailsWorkshop/ExpertDetailsWorkshop'
import BlogsCard from '../../Components/BlogsCard/BlogsCard'
import BlogSlider from '../../Components/BlogSlider/BlogSlider'
import PricingCards from '../../Components/PricingCards/PricingCards'

function MentalHelth() {
    function CustomPrevArroW(props) {
        // console.log(props, "sanjay123")
        const { onClick } = props;
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
        return (
            <button
                // className="custom-arrow-banner custom-prev-arrow-banner"
                className={`${styles.customarrow} ${styles.customprevarrow}`}
                onClick={onClick}
            >
                <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
            </button>
        );
    }

    function CustomNextArrow(props) {
        const { onClick } = props;
        return (
            <button
                // className="custom-arrow-banner custom-next-arrow-banner"
                className={`${styles.customarrow} ${styles.customnextarrow}`}
                onClick={onClick}
            >
                <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
            </button>
        );
    }

    var settings = {
        className: styles.slickstyles,
        dots: true,
        autoplay: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        arrows: false,
        // fade: true,
        autoplaySpeed: 2000,
        prevArrow: <CustomPrevArroW />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };
    return (
        <div className={styles.containeer}>

            <section>
                <ExpertSlider />
            </section>
            <h1 className={styles.mental}>Mental Health</h1>

            <div className={styles.desktopTypesContainer}>

                {data.map((item) => {
                    return (
                        <Types />
                    )
                })}
            </div>
            <div className={styles.mobileTypesContainer}>

                <Slider {...settings}>
                    {data.map((item) => {
                        return (
                            <Types />
                        )
                    })}

                </Slider>

            </div>








            <section >
                <ExpertWeHave />
            </section>
            <h1 className={styles.physical}>Physical Health</h1>

            <section className={styles.services}>
                <p className={styles.servicesp}>SERVICES</p>
                <Services text={name} />
            </section>

            <section className={styles.cards}>
                <h2 className={styles.skills}>Upgrade your skills</h2>
                <PricingCards />
            </section>

            <section className={styles.blogCards}>
                <h1 className={styles.blogs}>
                    BLOGS
                </h1>
                <BlogSlider />
            </section>
        </div>

    )
}

export default MentalHelth
const name =
{
    category: "SERVICES"

}
const data = [{
    id: 1
},
{ id: 2 },
{ id: 3 },
{ id: 4 },
{ id: 5 },
{ id: 6 }
]