import React from "react";
import "./SessionDataPage.css";
import SessionDataCard from "../../Components/Feedback/SessionDataCard";
import { open_api } from "../../Utils/network";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

const SessionDataPage = () => {
  const [questions, setQuestions] = useState([]);
  const { expertSlug, bookingId, packageID } = useParams();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await open_api.get(
          `/session/experts/${expertSlug}/questions/${packageID}`
        );
        //console.log(response.data.results);
        setQuestions(response.data.results);
      } catch (error) {
        console.error("Failed to fetch questions:", error);
      }
    };

    fetchQuestions();
  }, [expertSlug, packageID, bookingId]);

  return (
    <div className="session-page">
      <div className="session-container">
        <SessionDataCard questions={questions} bookingId={bookingId} />
      </div>
    </div>
  );
};

export default SessionDataPage;
