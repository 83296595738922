import React, { createContext, useContext, useEffect, useState } from "react";

// Create the AuthContext
const AuthContext = createContext();

// Create the AuthProvider to wrap your application
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [bearerToken, setBearerToken] = useState("");

  useEffect(() => {
    // Load user info from localStorage
    const token = localStorage.getItem("bearerToken");
    const storedUserName = localStorage.getItem("userName");
    const storedUserId = localStorage.getItem("userId");

    if (token && storedUserName && storedUserId) {
      setIsAuthenticated(true);
      setUserName(storedUserName);
      setUserId(storedUserId);
      setBearerToken(token);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const logout = () => {
    // Clear session and local storage
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");

    // Reset auth state
    setIsAuthenticated(false);
    setUserName("");
    setUserId("");
    setBearerToken("");
  };

  // Provide the state and functions to children
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        userName,
        userId,
        bearerToken,
        logout,
        setUserId,
        setBearerToken,
        setUserName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use AuthContext in components
export const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
