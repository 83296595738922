import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabsBottom from "../TabsBottom/TabsBottom";
import styles from "./LabTabs.module.css";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import { open_api } from "../../Utils/network";

export default function LabTabs() {
  const [value, setValue] = React.useState("2");
  let pink = "#FF67A1";
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      height: "5px",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: "100%",
      width: "100%",
      backgroundColor: "#FF67A1",
      borderRadius: "10px",
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      color: "rgba(255, 255, 255, 0.7)",
      "&.Mui-selected": {
        color: "#FF67A1",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  const [details, setDetails] = useState([]);

  useEffect(() => {
    getWorkshop();
    // workshopCateg();
  }, [value]);

  const workshopCateg = () => {
    open_api
      .get(`workshop/workshop_category/`)
      .then((res) => {
        // console.log(res?.data?.data);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const getWorkshop = (id) => {
    open_api
      .get(`home/workshop/?workshop_type=${value}`)
      // .get(`profiles/experts/${id}/workshops`)
      .then((res) => {
        // console.log(res?.data?.data);
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          setDetails(res?.data?.data);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
    // console.log(details,"pricing")
  };

  return (
    <>
      <div className={styles.top}>
        <h1>
          Care,
          <span> for every need </span>
        </h1>
      </div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box>
            {/* <StyledTabs onChange={handleChange} aria-label="lab API tabs example" centered
              selectedTextColor="#FF67A1" // Set text color for active tab

              indicatorColor="secondary" >
              
            </StyledTabs> */}

            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="styled tabs example"
              centered
            >
              <StyledTab
                sx={{
                  color: "#A490FE",
                  fontWeight: "700",
                  fontSize: "22px",
                  textTransform: "inherit",
                }}
                label="Child Care"
                value="2"
                className={styles.tabsName}
              />
              {/* <StyledTab sx={{ color: '#A490FE', fontWeight: "700", fontSize: "22px", textTransform: "inherit" }} label="Self Care" value="1" className={styles.tabsName} />
              <StyledTab sx={{ color: '#A490FE', fontWeight: "700", fontSize: "22px", textTransform: "inherit" }} label="Pregnancy Care " value="3" className={styles.tabsName} /> */}
            </StyledTabs>
            <Box />
          </Box>
          <TabPanel value="1">{<TabsBottom data={details} />}</TabPanel>
          <TabPanel value="2">{<TabsBottom data={details} />}</TabPanel>
          <TabPanel value="3">{<TabsBottom data={details} />}</TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
