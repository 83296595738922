import React, { useState } from "react";
import "./AccountDeleteCard.css"; // Add custom CSS for styling

const AccountDeleteCard = ({ onClose, onDelete }) => {
  const [inputValue, setInputValue] = useState("");
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setIsDeleteConfirmed(value === "delete");
  };

  return (
    <div className="account-delete-container">
      <div className="account-delete-card">
        <h2>Are you sure you want to delete the account?</h2>
        <p>
          Your account will be set to inactive and will be deleted after 30
          days.
        </p>
        <label>Type "delete" to confirm your account deletion:</label>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Type delete"
          className="delete-input"
        />
        <div className="actions">
          <button onClick={onClose} className="cancel-button">
            Cancel
          </button>
          <button
            disabled={!isDeleteConfirmed}
            onClick={onDelete}
            className={isDeleteConfirmed ? "confirm-button" : "disabled-button"}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountDeleteCard;
