import React from "react";
import Typewriter from "typewriter-effect";
import styles from "./index.module.css";

const RotatingText = () => {
  return (
    <h2 className={styles.heading}>
      <span className={styles.inline}>JOIN US AS </span>
      <Typewriter
        options={{
          strings: ["AN EXPERT", "A CONTENT CREATOR", "A BLOGGER"],
          autoStart: true,
          loop: true,
          deleteSpeed: 50,
          delay: 75,
        }}
      />
    </h2>
  );
};

export default RotatingText;
