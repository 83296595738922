import React from "react";
import styles from "./TabsBottom.module.css";
import correct from "../../Assets/correct.png";
import sheth from "../../Assets/sheth.png";
import saxena from "../../Assets/saxena.png";
import deshmukh from "../../Assets/deshmukh.png";
import { Button } from "@mui/material";
import CareDetails from "../CareDetails/CareDetails";

function TabsBottom({ data }) {
  //console.log(data, "TabsBottom");
  return (
    <div>
      <div className={styles.bottom}>
        <div className={styles.left}>
          <div className={styles.wrap}>
            <h1>Empowering Parents with Expert-Led Child Care Solutions</h1>
            <div>
              <div className={styles.correct}>
                <div>
                  <img src={correct}></img>
                </div>
                <p>
                  Tailored advice and strategies for your child’s unique needs
                </p>
              </div>
              <div className={styles.correct}>
                <div>
                  <img src={correct}></img>
                </div>
                <p>
                  Access to top professionals in child development and parenting
                </p>
              </div>
              <div className={styles.correct}>
                <div>
                  <img src={correct}></img>
                </div>
                <p>
                  Interactive activities and tools to foster healthy growth and
                  learning
                </p>
              </div>
            </div>
          </div>
          <div className={styles.btn}>
            {/* <Button variant="contained" className={styles.app}>Download the app</Button> */}
          </div>
        </div>
        <div className={styles.right}>
          {data?.slice(0, 3).map((item) => {
            return <CareDetails data={item} key={item.id} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default TabsBottom;
const data = [
  {
    id: 1,
    img: sheth,
    desc: "Wellness sessions with top creators",
    name: "Dr. Rikita Sheth,",
    special: "Health Expert & Educator",
  },
  {
    id: 2,
    img: saxena,
    desc: "Wellness sessions with top creators",
    name: "Dr. Rikita Sheth,",
    special: "Health Expert & Educator",
  },
  {
    id: 3,
    img: deshmukh,
    desc: "Wellness sessions with top creators",
    name: "Dr. Rikita Sheth,",
    special: "Health Expert & Educator",
  },
];
