import React from "react";
import "./CongratulationsBanner.css";

const CongratulationsBanner = ({ priceSymbol, discount }) => {
  return (
    <div className="congratsBanner">
      <span role="img" aria-label="party popper">
        🎉
      </span>
      Congratulations! you save {priceSymbol}
      {discount} on this workshop
    </div>
  );
};

export default CongratulationsBanner;
