import React from "react";
import styles from "./PackageInfo.module.css";
import { ReactComponent as SessionIcon } from "../../Assets/session-icon.svg";
import { ReactComponent as ClockIcon } from "../../Assets/clock-icon.svg";
import { ReactComponent as VideoIcon } from "../../Assets/video-icon.svg";

const PackageInfo = ({
  title,
  sessions,
  duration,
  type,
  priceSymbol,
  price,
  originalPrice,
  discount,
}) => {
  const formatDuration = (duration) => {
    const [hours, minutes] = duration.split(":").map(Number);

    if (hours > 0) {
      return `${hours * 60 + minutes} mins`;
    }
    return `${minutes} mins`;
  };
  return (
    <div className={styles.packageInfo}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.details}>
        <div className={styles.detailItem}>
          <SessionIcon className={styles.icon} />
          <span>
            {sessions} {sessions === 1 ? "session" : "sessions bundle"}
          </span>
        </div>
        <span className={styles.separator}>|</span>
        <div className={styles.detailItem}>
          <ClockIcon className={styles.icon} />
          <span>{formatDuration(duration)}</span>
        </div>
        <span className={styles.separator}>|</span>
        <div className={styles.detailItem}>
          <VideoIcon className={styles.icon} />
          <span>{type}</span>
        </div>
      </div>
      <div className={styles.priceContainer}>
        <div className={styles.priceCard}>
          {discount && (
            <span className={styles.originalPrice}>
              {priceSymbol}
              {originalPrice}
            </span>
          )}
          <span className={styles.discountedPrice}>
            {priceSymbol}
            {price}
          </span>
        </div>
        {discount && (
          <div className={styles.tagline}>
            <span className={styles.taglineText}>Most Popular</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PackageInfo;
