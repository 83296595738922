import React from "react";
import "./BookingExpertDetails.css";

const BookingExpertDetails = (expert) => {
  return (
    <div className="expert-details">
      <div className="expert-image-container">
        <img
          src={expert?.expert.image}
          alt={`${expert?.expert.user?.first_name} ${expert?.expert.user?.last_name}`}
          className="expert-image"
        />
      </div>
      <div className="expert-info">
        <h1>{`${expert?.expert.user?.first_name} ${expert?.expert.user?.last_name}`}</h1>
        <p className="expert-qualification">
          {expert?.expert.specialist_tagline}
        </p>
        <div className="social-links">
          {expert?.expert?.facebook != null ? (
            <>
              <a
                href={expert?.expert.facebook}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook"></i>
              </a>
            </>
          ) : null}
          {expert?.expert?.instagram != null ? (
            <>
              <a
                href={expert?.expert.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BookingExpertDetails;
