import React, { useState, useEffect } from "react";
import "./SessionDataCard.css";
import useAuth from "../Hook/useAuth";
import { useNavigate } from "react-router-dom";
import { useDialog } from "../Dialog/DialogContext";
import LoginDialog from "../Login/LoginCard";
import successIcon from "../../Assets/feedback/successIcon.svg";
import { open_api_with_user_token } from "../../Utils/network";

const SessionDataCard = ({ questions, bookingId }) => {
  const { isAuthenticated, bearerToken } = useAuth();
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const { openLoginDialog } = useDialog();
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionFailed, setSubmissionFailed] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();

  // State to hold the user's answers
  const [answers, setAnswers] = useState({});

  // Handle text input change
  const handleTextChange = (questionId, value) => {
    if (!isAuthenticated) {
      openLoginDialog();
      return;
    }
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: { text_answer: value },
    }));
  };

  // Handle multiple choice selection
  const handleOptionChange = (questionId, optionId, isSelected) => {
    if (!isAuthenticated) {
      openLoginDialog();
      return;
    }
    setAnswers((prevAnswers) => {
      const selectedOptions =
        prevAnswers[questionId]?.selected_option_ids || [];
      return {
        ...prevAnswers,
        [questionId]: {
          selected_option_ids: isSelected
            ? [...selectedOptions, optionId]
            : selectedOptions.filter((id) => id !== optionId),
        },
      };
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!isAuthenticated) {
      openLoginDialog();
      return;
    }

    const formattedAnswers = Object.keys(answers).map((questionId) => ({
      question: parseInt(questionId),
      ...answers[questionId],
    }));

    const payload = { answers: formattedAnswers };

    try {
      const response = await open_api_with_user_token(bearerToken).post(
        `/session/bookings/${bookingId}/responses/`,
        payload
      );
      if (response.status === 200 || response.status === 201) {
        setSubmissionSuccess(true);
      } else {
        throw new Error("Failed to submit");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setSubmissionFailed(true);
    }
  };

  const handleLoginSuccess = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (submissionSuccess) {
      const intervalId = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 1) {
            return prevCountdown - 1;
          } else {
            clearInterval(intervalId);
            navigate("/profile/sessions/upcoming");
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [submissionSuccess, navigate]);

  if (submissionSuccess) {
    return (
      <div className="workshop-card">
        <img src={successIcon} alt="Success" />
        <h2>Response Submitted</h2>
        <p>
          Thank you for submitting your details. This helps the expert to serve
          you better in your upcoming one on one session.
        </p>
        <p>Redirecting to orders in {countdown} seconds</p>
      </div>
    );
  }

  return (
    <div className="session-data-card">
      <div className="questions-container">
        <h3>Please fill the below details for your one on one session</h3>
        {questions.map((question) => (
          <div key={question.id} className="question-item">
            <p className="question-text">{question.text}</p>
            {question.question_type === "text" ? (
              <textarea
                className="question-input"
                onChange={(e) => handleTextChange(question.id, e.target.value)}
                value={answers[question.id]?.text_answer || ""}
              />
            ) : (
              <div className="options-container">
                {question.options.map((option) => (
                  <label key={option.id} className="option-label">
                    <input
                      type="checkbox"
                      checked={
                        answers[question.id]?.selected_option_ids?.includes(
                          option.id
                        ) || false
                      }
                      onChange={(e) =>
                        handleOptionChange(
                          question.id,
                          option.id,
                          e.target.checked
                        )
                      }
                    />
                    {option.text}
                  </label>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <button className="submit-button" onClick={handleSubmit}>
        Submit
      </button>
      <h3 className="skipText" onClick={handleSubmit}>
        Skip
      </h3>
      {isLoginDialogOpen && (
        <LoginDialog handleLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );
};

export default SessionDataCard;
