import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#7A53FF',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            backgroundColor: '#F6F6F6', // Change this to your desired color
          },
          '& .MuiFilledInput-underline:before': {
            borderBottomColor: '#7A53FF', // Change this to your desired color
          },
          '& .MuiFilledInput-underline:hover:before': {
            borderBottomColor: '#7A53FF', // Change this to your desired color
          },
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: '#7A53FF', // Change this to your desired color
          },
        },
      },
    },
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#ff67a1', // Change this to your desired color
    //       color: '#FFFFFF', // Change this to your desired color
    //       '&:hover': {
    //         backgroundColor: '#ff67a1', // Change this to your desired color for hover state
    //       },
    //     },
    //     contained: {
    //       backgroundColor: '#ff67a1', // Change this to your desired color
    //       color: '#FFFFFF', // Change this to your desired color
    //       '&:hover': {
    //         backgroundColor: '#ff67a1', // Change this to your desired color for hover state
    //       },
    //     },
    //     text: {
    //       backgroundColor: 'transparent',
    //       color: '#7A53FF', // Change this to your desired color
    //       '&:hover': {
    //         backgroundColor: 'rgba(122, 83, 255, 0.04)', // Optional: Add a background color on hover
    //       },
    //     },
    //   },
    // },
  },
});

export default theme;