import React from "react";
import WorkshopFeedbackCard from "../../Components/Feedback/WorkshopFeedbackCard";
import "./WorkshopFeedbackPage.css";

function WorkshopFeedbackPage() {
  return (
    <div className="workshop-feedback-page">
      <div className="workshop-feedback-container">
        <WorkshopFeedbackCard />
      </div>
    </div>
  );
}

export default WorkshopFeedbackPage;
