import React, { useEffect, useState } from "react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./OrderDetails.css";
import { formatTimeTo12Hour, formatDateToReadable } from "../../Dialog/helper";
import circular from "../../../Assets/course_page/circular.svg";
import SessionsConducted from "./SessionsConducted";
import { open_api_with_user_token } from "../../../Utils/network";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../../Dialog/LoadingOverlay";

const OrderDetailsSession = ({ orderDetails, orderId }) => {
  const [sessions, setSessions] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if `orderDetails` is available before making the API call
    if (orderDetails && orderDetails?.session_purchase) {
      const fetchSessionInfo = async () => {
        try {
          const token = localStorage.getItem("bearerToken");
          const response = await open_api_with_user_token(token).get(
            `session/booked_slot/purchase/${orderDetails?.session_purchase?.id}`
          );

          // Handle response status
          if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 204
          ) {
            setSessions(response?.data?.results); // Assuming the session data is in `results`
          } else {
            throw new Error("Failed to fetch session data");
          }
        } catch (error) {
          console.error("Error fetching sessions:", error);
        } finally {
          setLoading(false); // Stop loading
        }
      };

      fetchSessionInfo();
    }
  }, [orderDetails, orderId]); // Make sure `orderId` and `orderDetails` are in dependencies

  const bookingTime = formatTimeTo12Hour(orderDetails?.time_created);
  const bookingDate = formatDateToReadable(orderDetails?.date_created);
  const currencySymbol =
    orderDetails?.session_purchase?.currency === "INR" ? "₹" : "$";

  const handleBookRemainingSession = () => {
    //console.log("calling the navigate");
    navigate(
      `/bookings/${orderDetails.session_purchase.expert_info.slug}/details?packageId=${orderDetails.session_purchase.package.id}`,
      {
        state: {
          bypassPurchase: true,
          bypassPackage: true,
          bookedPackageId: orderDetails.session_purchase.package.id,
          purchaseId: orderDetails.session_purchase.id,
          purchasedUserId: orderDetails.session_purchase.user_detail.id,
          duration:
            orderDetails.session_purchase.package.slot_duration.duration,
          isDataRequired:
            orderDetails.session_purchase.package.is_user_data_required,
          remainingSession: orderDetails.session_purchase.remaining_sessions,
        },
      }
    );
  };

  if (loading) {
    return <LoadingOverlay />;
  }

  if (!orderDetails) {
    return <p>No order details available</p>;
  }

  return (
    <>
      <div className="workshop-purchase-details-container">
        {/* Left Section */}
        <div className="workshop-purchase-details-left">
          <div className="expertImageSection">
            <img src={circular} className="svgBackground" alt="background" />
            <img
              src={orderDetails.session_purchase?.expert_info?.image}
              alt={orderDetails.session_purchase?.expert_info?.slug}
              className="expertImage"
            />
            <h3 className="expertName">
              {orderDetails.session_purchase?.expert_info?.first_name}{" "}
              {orderDetails.session_purchase?.expert_info?.last_name}
            </h3>
            {orderDetails.session_purchase?.expert_info?.specialist_tagline && (
              <p className="expertTagline">
                {orderDetails.session_purchase?.expert_info?.specialist_tagline}
              </p>
            )}
            {orderDetails?.session_purchase?.purchase_status === 2 &&
            orderDetails?.session_purchase?.package?.sessions_count >
              sessions.length ? (
              <>
                <Button
                  variant="contained"
                  className="feedback-button"
                  onClick={handleBookRemainingSession}
                >
                  Book remaining Sessions
                </Button>
              </>
            ) : (
              <></>
            )}
            <h4>
              Remaining Session:{" "}
              {orderDetails?.session_purchase?.remaining_sessions}
            </h4>
          </div>
        </div>

        {/* Right Section */}
        <div className="workshop-purchase-details-right">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ backgroundColor: "#e3e3e3" }}
            >
              <Typography>Expert Session Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="booking-info">
                <div>
                  <span>Expert Name:</span>
                  <span>
                    {orderDetails?.session_purchase?.expert_info?.first_name}{" "}
                    {orderDetails?.session_purchase?.expert_info?.last_name}
                  </span>
                </div>
                <div>
                  <span>Package Name:</span>
                  <span>{orderDetails?.session_purchase?.package?.title}</span>
                </div>
                <div>
                  <span>Total Sessions:</span>
                  <span>
                    {orderDetails?.session_purchase?.package?.sessions_count}
                  </span>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          {/* Booking Details */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ backgroundColor: "#e3e3e3" }}
            >
              <Typography>Booking Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="booking-info">
                <div>
                  <span>Booking ID:</span>
                  <span>{orderDetails?.order?.order_id}</span>
                </div>
                <div>
                  <span>Booking Date:</span>
                  <span>{bookingDate}</span>
                </div>
                <div>
                  <span>Booking Time:</span>
                  <span>{bookingTime}</span>
                </div>
                <div className="booking-main-info">
                  <span className="grand-total-label">Booking Total:</span>
                  <span className="grand-total-price">
                    {currencySymbol}
                    {orderDetails?.session_purchase?.total_pay}
                  </span>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Payment Details */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ backgroundColor: "#e3e3e3" }}
            >
              <Typography>Payment Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="payment-info">
                <div>
                  <span>Payment Method:</span>
                  <span>{orderDetails?.payment_method_str}</span>
                </div>
                <div>
                  <span>Payment ID:</span>
                  <span>{orderDetails?.transaction_id}</span>
                </div>
                <div className="booking-main-info">
                  <span className="grand-total-label">Payment Status:</span>
                  <span
                    className={`grand-payment-status ${orderDetails?.payment_status_str.toLowerCase()}`}
                  >
                    {orderDetails?.payment_status_str}
                  </span>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Price Info */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ backgroundColor: "#e3e3e3" }}
            >
              <Typography>Price Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="price-info">
                <div>
                  <span>Item Total (Tax incl.):</span>
                  <span>
                    {currencySymbol}
                    {orderDetails?.session_purchase?.mrp}
                  </span>
                </div>
                <div>
                  <span>Discount:</span>
                  <span>
                    {currencySymbol}
                    {orderDetails?.session_purchase?.discount_amt}
                  </span>
                </div>
                <div>
                  <span>Coupon discount amount:</span>
                  <span>
                    {currencySymbol}
                    {orderDetails?.session_purchase?.coupon_discount_amt}
                  </span>
                </div>
                {/* <div>
                  <span>GST Charges:</span>
                  <span>
                    {currencySymbol}
                    {orderDetails.session_purchase?.gst_amt}
                  </span>
                </div> */}
                <div>
                  <span>Platform Fee:</span>
                  <span>
                    {currencySymbol}
                    {orderDetails?.session_purchase?.platform_amt}
                  </span>
                </div>
                <div className="booking-main-info">
                  <span className="grand-total-label">Grand Total:</span>
                  <span className="grand-total-price">
                    {currencySymbol}
                    {orderDetails?.session_purchase?.total_amt}
                  </span>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      {/* Render Sessions Conducted */}
      {sessions && (
        <SessionsConducted
          sessions={sessions}
          total_session={orderDetails?.session_purchase?.total_sessions}
        />
      )}
    </>
  );
};

export default OrderDetailsSession;
