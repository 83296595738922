import React, { createContext, useContext, useState } from "react";

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const [isLoginDialogOpen, setLoginDialogOpen] = useState(false);

  const openLoginDialog = () => setLoginDialogOpen(true);
  const closeLoginDialog = () => setLoginDialogOpen(false);

  return (
    <DialogContext.Provider
      value={{ isLoginDialogOpen, openLoginDialog, closeLoginDialog }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);
