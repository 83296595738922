import axios from "axios";
// export const base_url = "http://43.204.99.206/";
// export const base_url = "https://myburgo.com/api";
export const isProduction = process.env.REACT_APP_IS_PRODUCTION === "true";
const isLocal = process.env.REACT_APP_IS_LOCAL === "true";

export const base_url = isLocal
  ? "http://127.0.0.1:8000/"
  : isProduction
    ? "https://prod.myburgo.com/"
    : "https://api.myburgo.com/";

export const open_api = axios.create({
  baseURL: base_url,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: (status) => status < 500,
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

export const open_api_with_user_token = (token) =>
  axios.create({
    baseURL: base_url,
    timeout: 300000,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": `application/json`,
    },
    validateStatus: (status) => status < 500,
    transformRequest: [
      function (data, headers) {
        return JSON.stringify(data);
      },
    ],
  });

export const open_api_without_auth = axios.create({
  baseURL: base_url,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: (status) => status < 500,
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

export const getCountryCode = async () => {
  let countryCode = sessionStorage.getItem("country_code");

  if (!countryCode) {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      countryCode = response.data.country_code.toLowerCase();
      sessionStorage.setItem("country_code", countryCode);
    } catch (error) {
      console.error("Error fetching the country code", error);
      countryCode = "in";
      sessionStorage.setItem("country_code", countryCode);
    }
  }

  return countryCode;
};

getCountryCode();
