import React from 'react'
import styles from './AboutUs.module.css'
import baby from '../../Assets/baby.png'
import { Button } from '@mui/material'
function AboutUs() {
    return (<>
        <div className={styles.container}>
            <div className={styles.left}>
                <img src={baby}></img>


            </div>

            <div className={styles.right}>
                <span className={styles.aboutbotton} variant='contained'>About Us</span>
                <div className={styles.middle}>

                    <h1 className={styles.h1}>Just as 'Burgo' means a fortified place in Spanish,
                    </h1>
                    <p className={styles.p}> 
                        we've created a secure and nurturing space for all parents,
                        a true home for every family.
                    </p>
                </div>

                <span>Our brand is dedicated to enriching the lives of parents by offering a wide range of services. From insightful online workshops to personalized expert guidance, a vibrant parent community for networking and assistance, wholesome meal plans, engaging worksheets, and fun activity classes for kids, and essential mental health support for parents – we are here for every step of your parenting adventure.</span>
            </div>




        </div>
      
        </>    )
}

export default AboutUs