import React, { useState } from 'react';
import styles from './ReadMore.module.css'
const ReadMore = ({ text, maxLength }) => {
    const [isTruncated, setIsTruncated] = useState(true);

    const toggleTruncate = () => {
        setIsTruncated(!isTruncated);
    };

    return (
        <div className={styles.para}>
            {isTruncated ? (
                <>
                    {text.slice(0, maxLength)}
                    {text.length > maxLength && <span>...</span>}
                </>
            ) : (
                text
            )}
            {text.length > maxLength && (
                <button onClick={toggleTruncate}className={styles.desc}>
               
                </button>
            )}
        </div>
    );
};

export default ReadMore;
