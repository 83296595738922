import React, { useState, useEffect } from "react";
import "./Account.css";
import { open_api_with_user_token } from "../../Utils/network";
import {
  fetchUserDetails,
  addAlert,
  fetchProfileTypes,
  removeAlert,
} from "../Dialog/helper";
import styled from "styled-components";
import CustomAlert from "../AlertDialog/CustomAlert";
import useAuth from "../Hook/useAuth";
import LoadingOverlay from "../Dialog/LoadingOverlay";

export const ErrorText = styled.label`
  color: red;
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 500;
  margin-top: 0;
`;

export const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
  &:focus {
    border-color: #6366f1;
    box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.5);
  }
`;

const Account = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [profileType, setProfileType] = useState("");
  const [profileTypeError, setProfileTypeError] = useState(false);
  const [profileTypes, setProfileTypes] = useState([]);
  const [token, setToken] = useState("");
  const [alerts, setAlerts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [userLocalId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [profileTypeId, setProfileTypeId] = useState(0);
  const { setUserName } = useAuth();

  // Fetch user details on component mount
  useEffect(() => {
    const getAccountDetails = async () => {
      const token = localStorage.getItem("bearerToken");
      const storedUserId = localStorage.getItem("userId");
      setToken(token);
      setUserId(storedUserId);
      try {
        const userInfo = await fetchUserDetails(token, storedUserId);

        // Fetch profile types from API
        await fetchProfileTypes(token, setProfileTypes);

        // Set user details
        setPhone(userInfo?.phone);
        setEmail(userInfo?.email);
        setFirstName(userInfo?.first_name);
        setLastName(userInfo?.last_name);

        // Set profile type by ID
        setProfileType(userInfo?.userprofile?.register_as?.title || "");
        setProfileTypeId(userInfo?.userprofile?.register_as?.id || "");
      } catch (error) {
        console.error("Failed to fetch user details", error);
      }
    };

    getAccountDetails();
  }, []);

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
  };
  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
  };

  const handleSave = async () => {
    setIsLoading(true);
    if (!firstName || !lastName || !phone || !email || !profileType) {
      setFirstNameError(!firstName);
      setLastNameError(!lastName);
      setPhoneError(!phone);
      setEmailError(!email);
      setProfileTypeError(!profileType);
      setIsLoading(false);
      setIsEditing(true);
      return;
    }
    const userInfoUpdated = await sumbitUserDetails();
    if (userInfoUpdated) {
      setIsEditing(false);
      setIsLoading(false);
    } else {
      setIsEditing(true);
      setIsLoading(false);
    }

    // Revert to view mode after saving
  };

  const handlefirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);

    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(value)) {
      setFirstNameError(value.trim() === "");
    } else {
      setFirstNameError(true);
    }
  };

  const handlelastNameChange = (e) => {
    const value = e.target.value;
    // Allow input updates, even with numbers
    setLastName(value);

    // Validate the input value
    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(value)) {
      setLastNameError(value.trim() === ""); // Check for empty field
    } else {
      setLastNameError(true); // Set error if it contains invalid characters
    }
  };

  const handleProfileTypeChange = (e) => {
    const selectedId = e.target.value;
    const selectedType = profileTypes.find(
      (type) => type.id.toString() === selectedId
    );
    if (selectedType) {
      setProfileType(selectedType.title);
      setProfileTypeId(selectedType.id);
    } else {
      setProfileType("");
    }
    setProfileTypeError(false);
  };

  const sumbitUserDetails = async () => {
    let registerAsId, title;
    switch (profileType.toLowerCase()) {
      case "mom":
        title = "Mrs";
        break;
      case "dad":
        title = "Mr";
        break;
      case "grand pa":
        title = "Mr";
        break;
      case "grand ma":
        title = "Mrs";
        break;
      default:
        title = "Mr/Mrs";
    }

    const userDetails = {
      id: userLocalId,
      phone: phone,
      first_name: firstName,
      last_name: lastName,
      email: email,
      register_as_id: profileTypeId,
      title: title,
    };
    try {
      const res = await open_api_with_user_token(token).patch(
        `/profiles/update_profile/`,
        userDetails
      );

      //console.log(res);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        localStorage.setItem("userName", firstName + " " + lastName);
        setUserName(firstName + " " + lastName);
        return true;
      } else if (res?.status === 400) {
        if (res?.data?.email) {
          setShowAlert(true);
          handleAddAlert("Input Error", res?.data?.email, "error", true);
          setIsLoading(false);
          return false;
        } else if (res?.data?.phone) {
          setShowAlert(true);
          handleAddAlert("Input Error", res?.data?.phone, "error", true);
          setIsLoading(false);
          return false;
        } else {
          setShowAlert(true);
          handleAddAlert("Input Error", res?.data, "error", true);
          setIsLoading(false);
          return false;
        }
      }
    } catch (err) {
      console.error("Error in sumbitUserDetails:", err);
      return false;
    }
    return false;
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <div className="account-container">
        <div className="account-header">
          <h2>Account</h2>
          <i
            className="fas fa-pencil-alt edit-icon"
            onClick={() => setIsEditing(true)}
          />
        </div>
        <form className="account-form">
          <div className="form-group">
            <label htmlFor="first-name">First Name:</label>
            <input
              type="text"
              id="first-name"
              value={firstName}
              onChange={handlefirstNameChange}
              disabled={!isEditing}
              className={!isEditing ? "disabled-input" : ""}
              style={{
                borderColor: firstNameError ? "red" : "#ccc",
              }}
            />
            {firstNameError && (
              <ErrorText>
                This field is required and should not contain any numbers
              </ErrorText>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="last-name">Last Name:</label>
            <input
              type="text"
              id="last-name"
              value={lastName}
              onChange={handlelastNameChange}
              disabled={!isEditing}
              className={!isEditing ? "disabled-input" : ""}
              style={{
                borderColor: lastNameError ? "red" : "#ccc",
              }}
            />
            {lastNameError && (
              <ErrorText>
                This field is required and should not contain any numbers
              </ErrorText>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={!isEditing}
              className={!isEditing ? "disabled-input" : ""}
              style={{
                borderColor: emailError ? "red" : "#ccc",
              }}
            />
            {emailError && <ErrorText>This field is required</ErrorText>}
          </div>
          <div className="form-group">
            <label htmlFor="phone-number">Phone Number:</label>
            <input
              type="tel"
              id="phone-number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              disabled={true}
              className="disabled-input"
              style={{
                borderColor: phoneError ? "red" : "#ccc",
              }}
            />
            {phoneError && <ErrorText>This field is required</ErrorText>}
          </div>
          <div className="form-group">
            <label htmlFor="profileType">Register As:</label>
            <Select
              id="profileType"
              name="profileType"
              value={profileTypeId}
              onChange={handleProfileTypeChange}
              disabled={!isEditing}
              className={!isEditing ? "disabled-input" : ""}
              style={{
                borderColor: profileTypeError ? "red" : "#ccc",
              }}
            >
              <option value="">Select a profile type</option>
              {profileTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.title}
                </option>
              ))}
            </Select>
            {profileTypeError && <ErrorText>This field is required</ErrorText>}
          </div>
          {isEditing && (
            <button type="button" className="button-1" onClick={handleSave}>
              Save
            </button>
          )}
        </form>
        {showAlert &&
          alerts.map((alert) => (
            <CustomAlert
              key={alert.id}
              title={alert.title}
              message={alert.message}
              onClose={() => handleRemoveAlert(alert.id)}
              autoClose={alert.autoClose}
              type={alert.type}
            />
          ))}
      </div>
    </>
  );
};

export default Account;
