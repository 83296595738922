import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
} from "react-share";
import { FaInstagram, FaShareAlt } from "react-icons/fa";
import styles from "./BlogInfo.module.css";
import { Rating } from "@mui/material";
import innerBLogBaby from "../../Assets/innerBLogBaby.png";

const handleInstagramShare = () => {
  const shareUrl = window.location.href;
  alert(
    "Instagram doesn't support direct sharing via web. Copy the link to share on Instagram."
  );
  navigator.clipboard.writeText(shareUrl); // Copy URL to clipboard
};

const handleShare = (platform) => {
  const shareUrl = window.location.href;
  const title = document.title;

  if (platform === "common") {
    if (navigator.share) {
      navigator
        .share({
          title: title,
          url: shareUrl,
        })
        .catch(console.error);
    } else {
      alert("Your browser doesn't support the Web Share API.");
    }
  }
};

const ArticleHeader = ({ data }) => {
  const shareUrl = window.location.href;
  const title = data?.title || "Article Title";

  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <h1>{title}</h1>
        <p className={styles.meta}>
          {data?.published_date} • {data?.read_time} min read
          {/* Social Share Buttons */}
          <div className={styles.socialShare}>
            {/* Facebook Share */}
            <FacebookShareButton url={shareUrl} quote={title}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            {/* Twitter Share */}
            <TwitterShareButton url={shareUrl} title={title}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>

            {/* LinkedIn Share */}
            <LinkedinShareButton url={shareUrl} title={title}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>

            {/* WhatsApp Share */}
            <WhatsappShareButton url={shareUrl} title={title}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            {/* Telegram Share */}
            <TelegramShareButton url={shareUrl} title={title}>
              <TelegramIcon size={32} round />
            </TelegramShareButton>

            {/* Common Share */}
            <FaShareAlt
              className={styles.icon}
              onClick={() => handleShare("common")}
            />
          </div>
        </p>

        <div className={styles.author}>
          {/* Left Side: Circular Author Image */}
          <img
            src={data?.authors?.[0]?.image || "default-author.jpg"} // Placeholder if image is not available
            alt={data?.authors?.[0]?.name || "Unknown Author"}
            className={styles.authorImage}
          />

          {/* Right Side: Author Name and Rating */}
          <div className={styles.authorDetails}>
            <p className={styles.authorName}>
              {data?.authors?.[0]?.name || "Unknown Author"}
            </p>
            <Rating name="read-only" value={5} readOnly />
          </div>
        </div>
      </div>
      <div className={styles.headerRight}>
        <div className="headerImage">
          <img
            src={
              data?.header_image_url?.url
                ? data?.header_image_url?.url
                : innerBLogBaby
            }
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ArticleHeader;
