import React, { useState, useEffect } from "react";
import useAuth from "../Hook/useAuth";
import "./WorkshopFeedbackCard.css";
import ratingIcon from "../../Assets/feedback/ratingIcon.svg";
import ratingSuccessIcon from "../../Assets/feedback/ratingSelectIcon.svg";
import successIcon from "../../Assets/feedback/successIcon.svg";
import LoginDialog from "../Login/LoginCard";
import { useNavigate, useParams } from "react-router-dom";
import { useDialog } from "../Dialog/DialogContext";
import { open_api_with_user_token, open_api } from "../../Utils/network";

const WorkshopFeedbackCard = () => {
  const navigate = useNavigate();
  const { isAuthenticated, bearerToken } = useAuth();
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [workshopName, setWorkshopName] = useState("");
  const [chipOptions, setChipOptions] = useState([]);
  const [selectedChips, setSelectedChips] = useState([]);
  const [starRating, setStarRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const { openLoginDialog, closeLoginDialog } = useDialog();
  const { workshopSlug } = useParams();
  const [expertIds, setExpertsIds] = useState([]);
  const [workshopId, setWorkshopId] = useState(0);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionFailed, setSubmissionFailed] = useState(false);
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    fetchWorkshopData();
  }, []);

  const fetchWorkshopData = async () => {
    try {
      await getWorkshopDetails(workshopSlug);
      const chipData = await open_api.get("/workshop/review_option");
      //console.log(chipData);
      setChipOptions(chipData.data.results);
    } catch (error) {
      console.error("Error fetching workshop data:", error);
    }
  };

  const getWorkshopDetails = async (workshopSlug) => {
    try {
      const res = await open_api.get(`/home/workshop/detail/${workshopSlug}/`);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        const data = res?.data?.data;
        setWorkshopName(data.title);
        setExpertsIds(data.workshop_experts);
        setWorkshopId(data.id);
      }
    } catch (err) {
      console.error("Error fetching workshop details:", err);
    }
  };

  const handleInteraction = (action) => {
    if (!isAuthenticated) {
      openLoginDialog();
    } else {
      action();
    }
  };

  const handleStarClick = (value) => {
    handleInteraction(() => setStarRating(value));
  };

  const handleChipClick = (chipId) => {
    setSelectedChips((prevSelected) =>
      prevSelected.includes(chipId)
        ? prevSelected.filter((id) => id !== chipId)
        : [...prevSelected, chipId]
    );
  };

  const handleSubmit = async () => {
    handleInteraction(async () => {
      const feedback = {
        workshop: workshopId,
        rating: starRating,
        review_option_ids: selectedChips,
        opinion: reviewText,
      };

      try {
        const response = await open_api_with_user_token(bearerToken).post(
          "/workshop/parent_review",
          feedback
        );
        if (response.status === 200 || response.status === 201) {
          setSubmissionSuccess(true);
        } else {
          throw new Error("Failed to submit");
        }
      } catch (error) {
        console.error("Error submitting feedback:", error);
        setSubmissionFailed(true);
      }
    });
  };

  const handleLoginSuccess = (userName) => {
    window.location.reload();
  };

  useEffect(() => {
    if (submissionSuccess) {
      const intervalId = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 1) {
            return prevCountdown - 1;
          } else {
            clearInterval(intervalId);
            navigate("/");
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(intervalId); // Clear the interval if the component unmounts
    }
  }, [submissionSuccess, navigate]);

  if (submissionSuccess) {
    return (
      <div className="workshop-card">
        <img src={successIcon} alt="Success" />
        <h2>Review Submitted</h2>
        <p>
          Thank you for submitting your review. This helps the expert and us to
          provide you with even better service.
        </p>
        <p>Redirecting to home page in {countdown} seconds</p>
      </div>
    );
  }

  if (submissionFailed) {
    return (
      <div className="workshop-card">
        <h2>Submission Failed</h2>
        <p>
          There was an issue submitting your review. Please try again later.
        </p>
        <button onClick={() => setSubmissionFailed(false)}>Try Again</button>
      </div>
    );
  }

  return (
    <div className="workshop-card">
      <h2>How was the workshop?</h2>
      <h3>{workshopName}</h3>

      <div className="stars">
        {[1, 2, 3, 4, 5].map((value) => (
          <img
            key={value}
            src={starRating >= value ? ratingSuccessIcon : ratingIcon}
            className="star"
            onClick={() => handleStarClick(value)}
            alt={`Star rating ${value}`}
            width="36"
            height="36"
            style={{ cursor: "pointer" }}
          />
        ))}
      </div>

      <div className="chips">
        {chipOptions.map((chip) => (
          <button
            key={chip.id}
            className={`chip ${selectedChips.includes(chip.id) ? "selected" : ""} ${
              chip.is_positive ? "positive" : "negative"
            }`}
            onClick={() => handleChipClick(chip.id)}
          >
            {chip.review_option}
          </button>
        ))}
      </div>

      <div className="review">
        <p>Please share your review about the workshop</p>
        <textarea
          placeholder="Your Answer"
          value={reviewText}
          onChange={(e) => setReviewText(e.target.value)}
        />
      </div>

      <button className="submit-button" onClick={handleSubmit}>
        Submit
      </button>

      {/* Login Dialog */}
      {isLoginDialogOpen && (
        <LoginDialog handleLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );
};

export default WorkshopFeedbackCard;
