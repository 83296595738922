import React from "react";
import styles from "./ExpertSlider.module.css";

import Slider from "react-slick";
import SliderImg from "../../Assets/SliderImg.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SliderImgMobile from "../../Assets/SliderImgMobile.png";

function ExpertSlider() {
  function CustomPrevArrow(props) {
    // console.log(props, "sanjay123")
    const { onClick } = props;
    return (
      <button
        // className="custom-arrow-banner custom-prev-arrow-banner"
        className={`${styles.customarrow} ${styles.customprevarrow}`}
        onClick={onClick}
      >
        <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
      </button>
    );
  }

  function CustomNextArrow(props) {
    const { onClick } = props;
    return (
      <button
        // className="custom-arrow-banner custom-next-arrow-banner"
        className={`${styles.customarrow} ${styles.customnextarrow}`}
        onClick={onClick}
      >
        <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
      </button>
    );
  }

  var settings = {
    className: styles.slickstyles,
    // dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: true,
    // fade: true,
    autoplaySpeed: 2000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {data.map((item) => {
          return (
            <div key={item.id} className={styles.Slider}>
              <div className={styles.desktop}>
                <img src={item.img}></img>
              </div>
              <div className={styles.mobile}>
                <img src={item.SliderImgMobile}></img>
              </div>
              <div className={styles.download}>
                <button className={styles.downloadeApp}>
                  Download the app
                </button>
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
}

export default ExpertSlider;
const data = [
  { img: SliderImg, id: 1, SliderImgMobile },
  { img: SliderImg, id: 2, SliderImgMobile },
  { img: SliderImg, id: 3, SliderImgMobile },
];
