import React from "react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./OrderDetails.css";
import { formatTimeTo12Hour, formatDateToReadable } from "../../Dialog/helper";
import { useNavigate } from "react-router-dom";

const OrderDetailsWorkshop = ({ orderDetails }) => {
  const navigate = useNavigate();
  const bookingTime = formatTimeTo12Hour(orderDetails?.time_created);
  const bookingDate = formatDateToReadable(orderDetails?.date_created);
  const currencySymbol =
    orderDetails?.workshop_purchase?.currency === "INR" ? "₹" : "$";

  const { live_workshop_url, whatsapp_group_url, recorded_session_url } =
    orderDetails?.workshop_purchase?.workshop || {};

  const hasLinks =
    live_workshop_url || whatsapp_group_url || recorded_session_url;

  const isPurchased =
    orderDetails?.payment_status_str?.toLowerCase() === "paid";

  const handleFeedbackLinkClick = () => {
    navigate(
      `/workshop/${orderDetails?.workshop_purchase?.workshop?.slug}/feedback`
    );
  };

  return (
    <div className="workshop-purchase-details-container">
      {/* Left Section */}
      <div className="workshop-purchase-details-left">
        <div className="workshop-purchase-details-image-container">
          <img
            src={orderDetails?.workshop_purchase?.workshop?.file}
            alt={orderDetails?.workshop_purchase?.workshop?.slug}
            className="workshop-purchase-details-image"
          />
          <div className="workshop-purchase-details-title">
            {orderDetails?.workshop_purchase?.workshop?.title}
          </div>
        </div>
        <p className="workshop-description">
          {orderDetails?.workshop_purchase?.workshop?.description}
        </p>
        <Button
          variant="contained"
          className="feedback-button"
          onClick={handleFeedbackLinkClick}
        >
          Write feedback
        </Button>
      </div>

      {/* Right Section */}
      <div className="workshop-purchase-details-right">
        {/* Booking Details */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: "#e3e3e3" }}
          >
            <Typography>Workshop Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="booking-info">
              <div>
                <span>Workshop Title:</span>
                <span>{orderDetails?.workshop_purchase?.workshop?.title}</span>
              </div>
              <div>
                <span>Workshop Category:</span>
                <span>
                  {orderDetails?.workshop_purchase?.workshop?.category?.title}
                </span>
              </div>
              <div>
                <span>Workshop Type:</span>
                <span>
                  {orderDetails?.workshop_purchase?.workshop?.type?.title}
                </span>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: "#e3e3e3" }}
          >
            <Typography>Booking Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="booking-info">
              <div>
                <span>Booking ID:</span>
                <span>{orderDetails?.order?.id}</span>
              </div>
              <div>
                <span>Booking Date:</span>
                <span>{bookingDate}</span>
              </div>
              <div>
                <span>Booking Time:</span>
                <span>{bookingTime}</span>
              </div>
              <div className="booking-main-info">
                <span className="grand-total-label">Booking Total:</span>
                <span className="grand-total-price">
                  {currencySymbol}
                  {orderDetails?.workshop_purchase?.total_pay}
                </span>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {/* Payment Details */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: "#e3e3e3" }}
          >
            <Typography>Payment Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="payment-info">
              <div>
                <span>Payment Method:</span>
                <span>{orderDetails?.payment_method}</span>
              </div>
              <div>
                <span>Payment ID:</span>
                <span>{orderDetails?.transaction_id}</span>
              </div>
              <div className="booking-main-info">
                <span className="grand-total-label">Payment Status:</span>
                <span
                  className={`grand-payment-status ${orderDetails?.payment_status_str.toLowerCase()}`}
                >
                  {orderDetails?.payment_status_str}
                </span>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {/* Price Info */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: "#e3e3e3" }}
          >
            <Typography>Price Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="price-info">
              <div>
                <span>Item Total (Tax incl.):</span>
                <span>
                  {currencySymbol}
                  {orderDetails?.workshop_purchase.mrp}
                </span>
              </div>
              <div>
                <span>Discount:</span>
                <span>
                  {currencySymbol}
                  {orderDetails?.workshop_purchase?.discount_amt}
                </span>
              </div>
              <div>
                <span>Coupon discount amount:</span>
                <span>
                  {currencySymbol}
                  {orderDetails?.workshop_purchase?.coupon_discount_amt}
                </span>
              </div>
              {/* <div>
                <span>GST Charges:</span>
                <span>
                  {currencySymbol}
                  {orderDetails.workshop_purchase.gst_amt}
                </span>
              </div> */}
              <div>
                <span>Platform Fee:</span>
                <span>
                  {currencySymbol}
                  {orderDetails?.workshop_purchase?.platform_amt}
                </span>
              </div>
              <div className="booking-main-info">
                <span className="grand-total-label">Grand Total:</span>
                <span className="grand-total-price">
                  {currencySymbol}
                  {orderDetails?.workshop_purchase?.total_amt}
                </span>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {hasLinks && isPurchased ? (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ backgroundColor: "#e3e3e3" }}
            >
              <Typography>Workshop Links</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="price-info">
                {live_workshop_url && (
                  <div>
                    <span>Live Workshop:</span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => window.open(live_workshop_url, "_blank")}
                      sx={{
                        fontSize: {
                          xs: "12px", // Mobile (Extra small)
                          sm: "14px", // Small screen
                          md: "16px", // Medium and above
                        },
                      }}
                    >
                      Go to Live Workshop
                    </Button>
                  </div>
                )}
                {whatsapp_group_url && (
                  <div>
                    <span>WhatsApp Group:</span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => window.open(whatsapp_group_url, "_blank")}
                      sx={{
                        fontSize: {
                          xs: "12px", // Mobile (Extra small)
                          sm: "14px", // Small screen
                          md: "16px", // Medium and above
                        },
                      }}
                    >
                      Join WhatsApp Group
                    </Button>
                  </div>
                )}
                {recorded_session_url && (
                  <div>
                    <span>Recorded Session:</span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        window.open(recorded_session_url, "_blank")
                      }
                      sx={{
                        fontSize: {
                          xs: "12px", // Mobile (Extra small)
                          sm: "14px", // Small screen
                          md: "16px", // Medium and above
                        },
                      }}
                    >
                      Watch Recorded Session
                    </Button>
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default OrderDetailsWorkshop;
